import AuthContext from '../Context/AuthContext';
import {useState, useMemo} from 'react';


export const AppWrapper = ({children}) => {
    const [auth, setAuth] = useState(null);
    const providerValue = useMemo(()=>({auth, setAuth}), [auth, setAuth]);

    return (
        <AuthContext.Provider value={providerValue}>
            {children}
        </AuthContext.Provider>
    )
}