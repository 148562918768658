import React, {useState, useRef, useEffect} from 'react';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import '../Styles/CreateCharacter.css';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/icons-material/Link';
import { CircularProgress } from '@mui/material';
import Warning from '@mui/icons-material/Warning';

const CHARACTERS_ENDPOINT = "game/account/characters"

const VOCATIONS = {
    "goku": 1,
    "vegeta": 2,
    "trunks": 3,
    "piccolo": 6,
    "yamcha": 14,
    "freezer": 8,
    "gohan": 4,
    "videl": 15,
    "cooler": 9,
    "dende": 7,
    "bebi": 11,
    "brolly": 5,
    "janemba": 13,
    "buu": 12,
    "cell": 10,
    "tenshin": 16
}

const GENDER = {
    "female": 0,
    "male": 1
}

export const CreateCharacter = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [name, setName] = useState("");
    const [gender, setGender] = useState("none");
    const [vocation, setVocation] = useState("none");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const nameRef = useRef();

    useEffect(() => {
        nameRef.current.focus();
    }, [])

    useEffect(() => {
        setError('');
    }, [name, gender, vocation])

    const handleSubmit = async (e) => {
        setError('');
        e.preventDefault();
        if (vocation === "none" || gender === "none"){
            setError('Select race and gender.');
            return;
        }
        try {
            setLoading(true);
            var form = new FormData()
            form.append('Name', name);
            form.append('Vocation', VOCATIONS[vocation]);
            form.append('Sex', GENDER[gender]);
            const response = await axiosPrivate.post(CHARACTERS_ENDPOINT,
              form);
            console.log(JSON.stringify(response?.data));
            setLoading(false);
            navigate('/account', {
              replace:true, state:{
                characterCreated:true
              }});
        } catch (err) {
            setLoading(false);
            if (!err?.response) {
                setError('Server is not responding. Try again later.');
            } else if (err.response?.status === 429) {
                setError('Woah! Slow down. Try again in a few minutes.');
            } else if (err.response?.status === 401) {
                navigate('/login', {
                    replace:true});
            } else {
              const errorCode = err?.response?.data?.code
              switch(errorCode){
                case 1: // ACCOUNT NOT FOUND
                setError('Account not found.');
                  break;
                case 2: // TOO MANY CHARACTERS
                setError("You can't create more characters on this account.");
                  break;
                case 3: // NAME LENGTH
                setError("Name length limits exceeded.");
                  break;
                case 4: // NAME CONTAINS ILLEGAL CHARACTERS
                setError("Name contains illegal characters.");
                  break;
                case 5: // NAME CONTAINS TOO SHORT WORDS
                setError("Name contains too short words.");
                  break;
                case 6: // NAME CONTAINS ILLEGAL WORDS
                setError('Name contains illegal words.');
                  break;
                case 7: // WRONG GENDER
                setError("Wrong gender.");
                  break;
                case 8: // WRONG VOCATION
                setError("Wrong vocation.");
                  break;
                case 9: // CHARACTER NAME IS ALREADY IN USE
                setError("Character name is already in use.");
                  break;
                case 10: // FAILED
                setError("Failed to create character. Please try again later.");
                  break;
                default:
                setError('Unknown error has occured. Try again later.');
                  break;
              }
            }
        }
    }

    const onGenderChange = e => {
        setGender(e.target.value);
    }

    const onVocationChange = e => {
        setVocation(e.target.value);
    }

  return (
    <Container component="main" maxWidth="xs">
        <Box 
        component="form"
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center'
        }}>
            <Paper elevation={16}
                sx={{
                    borderRadius: 10,
                }}>
                <Typography
                    variant="h4"
                    margin="15px"
                    color="white" 
                    textAlign="center">Create Character</Typography>
                <Stack orientation="vertical" paddingX="20px">
                    <TextField variant="outlined"
                        required
                        id="nameField"
                        label="Character Name"
                        autoComplete="off"
                        color="primary"
                        value={name}
                        ref={nameRef}
                        onInput={e=>setName(e.target.value)} 
                        sx={{
                            input:{color:'white', textAlign:'left'}
                        }}/>
                    <div className="cc-selector" style={{marginTop: '15px'}} onChange={onGenderChange}>
                        <div className="char-container" title="Male">
                            <input id="male" type="radio" name="gender" value="male"/>
                            <label className="drinkcard-cc male" htmlFor="male"></label>
                        </div>
                        <div className="char-container" title="Female">
                            <input id="female" type="radio" name="gender" value="female" />
                            <label className="drinkcard-cc female" htmlFor="female"></label>
                        </div>
                    </div>
                    <Divider/>
                    <div className="cc-selector" style={{marginTop: '15px', marginBottom:'15px'}} onChange={onVocationChange}>
                        <div className="char-container" title="Goku">
                            <input id="goku" type="radio" name="character" value="goku"/>
                            <label className="drinkcard-cc goku" htmlFor="goku"></label>
                        </div>
                        <div className="char-container" title="Vegeta">
                            <input id="vegeta" type="radio" name="character" value="vegeta" />
                            <label className="drinkcard-cc vegeta" htmlFor="vegeta"></label>
                        </div>
                        <div className="char-container" title="Trunks">
                            <input id="trunks" type="radio" name="character" value="trunks" />
                            <label className="drinkcard-cc trunks" htmlFor="trunks"></label>
                        </div>
                        <div className="char-container" title="Piccolo">
                            <input id="piccolo" type="radio" name="character" value="piccolo" />
                            <label className="drinkcard-cc piccolo" htmlFor="piccolo"></label>
                        </div>
                        <div className="char-container" title="Yamcha">
                            <input id="yamcha" type="radio" name="character" value="yamcha" />
                            <label className="drinkcard-cc yamcha" htmlFor="yamcha"></label>
                        </div>
                        <div className="char-container" title="Freezer">
                            <input id="freezer" type="radio" name="character" value="freezer" />
                            <label className="drinkcard-cc freezer" htmlFor="freezer"></label>
                        </div>
                        <div className="char-container" title="Gohan">
                            <input id="gohan" type="radio" name="character" value="gohan" />
                            <label className="drinkcard-cc gohan" htmlFor="gohan"></label>
                        </div>
                        <div className="char-container" title="Videl">
                            <input id="videl" type="radio" name="character" value="videl" />
                            <label className="drinkcard-cc videl" htmlFor="videl"></label>
                        </div>
                        <div className="char-container" title="Cooler">
                            <input id="cooler" type="radio" name="character" value="cooler" />
                            <label className="drinkcard-cc cooler" htmlFor="cooler"></label>
                        </div>
                        <div className="char-container" title="Dende">
                            <input id="dende" type="radio" name="character" value="dende" />
                            <label className="drinkcard-cc dende" htmlFor="dende"></label>
                        </div>
                        <div className="char-container" title="Bebi">
                            <input id="bebi" type="radio" name="character" value="bebi" />
                            <label className="drinkcard-cc bebi" htmlFor="bebi"></label>
                        </div>
                        <div className="char-container" title="Brolly">
                            <input id="brolly" type="radio" name="character" value="brolly" />
                            <label className="drinkcard-cc brolly" htmlFor="brolly"></label>
                        </div>
                        <div className="char-container" title="Janemba">
                            <input id="janemba" type="radio" name="character" value="janemba" />
                            <label className="drinkcard-cc janemba" htmlFor="janemba"></label>
                        </div>
                        <div className="char-container" title="Buu">
                            <input id="buu" type="radio" name="character" value="buu" />
                            <label className="drinkcard-cc buu" htmlFor="buu"></label>
                        </div>
                        <div className="char-container" title="Cell">
                            <input id="cell" type="radio" name="character" value="cell" />
                            <label className="drinkcard-cc cell" htmlFor="cell"></label>
                        </div>
                        <div className="char-container" title="Tenshin">
                            <input id="tenshin" type="radio" name="character" value="tenshin" />
                            <label className="drinkcard-cc tenshin" htmlFor="tenshin"></label>
                        </div>
                    </div>
                    <Divider/>
                    <Button variant="contained" type="submit" sx={{marginTop:'20px', marginBottom:'10px'}}>CREATE CHARACTER</Button>
                    <Button sx={{marginBottom:'15px'}} onClick={()=>{window.open('https://dbwots.pl/index.php/races/');}}>Learn about races</Button>
                    {error?.length > 0 ? <Grid container orientation="row" justifyContent="center" sx={{marginBottom:'10px'}}>
                          <Grid item xs="auto"> 
                            <Warning color='error'/>
                          </Grid>
                          <Grid item xs="auto">
                            <Typography variant="h7" color='error'>
                              {error}
                            </Typography>
                          </Grid>
                      </Grid> : null}
                    {loading ? <CircularProgress/> : null}
                </Stack>
            </Paper>
        </Box>
    </Container>
  )
}