import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAccount from "../Hooks/useAccount";
import {useRef} from "react";

const RequireAuth = ({ access }) => {
    const location = useLocation();
    const account = useAccount();
    var logged = useRef(null);
    var dataAccess = useRef(0);

    const checkAccess = () => {
        return dataAccess.current >= Number(access) ? <Outlet /> : <Navigate to="/unauthorized" state={{ from: location }} replace />
    }

    const checkLogged = () => {
        if (logged.current === null){
            const accInfo = account.getInfo();
            logged.current = accInfo.logged;
            dataAccess.current = accInfo?.data?.access;
        }
        return logged.current ? checkAccess() : <Navigate to="/login" state={{ from: location }} replace />
    }

    return (
        checkLogged()
    );
}

export default RequireAuth;