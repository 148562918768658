import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import {useState, useRef, useEffect} from 'react';
import axios from '../Api/axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {themeOptions} from '../Styles/Theme';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

const HPBar = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f7b4b0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: '#f04035',
  },
}));

const KIBar = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#b6f2f1',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: '#1dbbf5',
    },
  }));

  const EXPBar = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#c1e3a6',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: '#6bd417',
    },
  }));


const ACTIONS_COLOR = {
    0 : '#89b369',
    1 : '#cc4e4e',
    2 : '#cc4e4e',
    3 : '#ad1f11'
}

const ACTIONS_NAME = {
    0 : 'Namelock',
    1 : 'Banishment',
    2 : 'Account Suspension',
    3 : 'Deletion'
}


const theme = createTheme(themeOptions());

const UNKNOWN_OUTFIT_SRC = "http://146.59.80.164/outfits/dynamic/unknown.png"

const SEARCH_ENDPOINT = "game/characters"

const FillZero = (num) => {
    return num > 9 ? ("0"+num.toString()) : num;
}

export const ConvertDateToString = (date) => {
    const _newDate = new Date(date);
    const td = new Date();
    let output = "";
    if (td.getFullYear() === _newDate.getFullYear() && td.getMonth() === _newDate.getMonth() && td.getDate() === _newDate.getDate())
        output = "Today at " + ("0" + _newDate.getHours()).slice(-2) + ":" + ("0" + _newDate.getMinutes()).slice(-2);
    else if (td.getFullYear() === _newDate.getFullYear() && td.getMonth() === _newDate.getMonth() && td.getDate() === _newDate.getDate() + 1)
        output = "Yesterday at " + ("0" + _newDate.getHours()).slice(-2) + ":" + ("0" + _newDate.getMinutes()).slice(-2);
    else{
        var _dateString = ("0" + _newDate.getDate()).slice(-2) + "/" + ("0"+(_newDate.getMonth()+1)).slice(-2) + "/" +
        _newDate.getFullYear() + " " + ("0" + _newDate.getHours()).slice(-2) + ":" + ("0" + _newDate.getMinutes()).slice(-2);
        output = _dateString;
    }
    if (parseInt(_newDate.getFullYear()) < 2000)
        return "Unknown";
    return output;
}

const getCountdownString = (date) => {

    let countDown = date - new Date().getTime();
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    
    if (days > 0)
        return days+" day"+(days!==1?"s":"");
    else if(hours > 0)
        return hours+"h "+minutes+"m "+seconds+"s";
    else if(minutes > 0)
        return minutes+" minute"+(minutes!==1?"s":"")+" and "+seconds+" second"+(seconds!==1?"s":"");
    else
        return seconds+" second"+(seconds!==1?"s":"");
  };

const CharacterView = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewData, setViewData] = useState({});
    const [foundChar, setFoundChar] = useState(false);
    const [lastFetchedObject, setLastFetchedObject] = useState("");
    const [displayError, setDisplayError] = useState("");
    const [failedPrompt, setFailedPrompt] = useState("");
    const [loading, setLoading] = useState(false);
    const hasSearchInput = useRef(false);
    const fetched = useRef(false);
    
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();


    const onSubmitSearch = (event) => {
        event.preventDefault();
        if (searchInput?.length > 0) {
            fetched.context = false;
            navigate('/search?name='+searchInput);
        }
    }

    useEffect(()=> {
        // call api to find a character by id/name
        let searchName = searchParams.get("name");
        let searchId = searchParams.get("id");
        hasSearchInput.context = searchName?.length > 0 || searchId > 0;
        
        const fetch_data = async () => {
            fetched.context = true;
            try {
                let endpoint = (searchName?.length > 0) ? (SEARCH_ENDPOINT + "?name=" + searchName) : (SEARCH_ENDPOINT + "?id=" + searchId);
                setLoading(true);
                const response = await axios.get(endpoint);
                setLoading(false);
                if (response?.status === 204) {
                    setDisplayError("Character not found.");
                    setSearchInput(searchName);
                    setFoundChar(false);
                    setFailedPrompt(searchName);
                } else {
                    setDisplayError("");
                    let _viewData = response?.data
                    _viewData.lastLogin = ConvertDateToString(_viewData?.lastLogin + 'Z');
                    _viewData.created = ConvertDateToString(_viewData?.created + 'Z');
                    if (_viewData?.deaths?.length > 0)
                    {
                        _viewData?.deaths.forEach((it, i) => {
                            _viewData.deaths[i].date = ConvertDateToString(it?.date + 'Z');
                        })
                    }
                    if (_viewData?.bans?.length > 0)
                    {
                        _viewData?.bans.forEach((it, i) => {
                            _viewData.bans[i].added = ConvertDateToString(it?.added + 'Z');
                            _viewData.bans[i].expiration =new Date(it?.expiration).getTime();
                            if (it?.isActive){
                                _viewData.suspended = true;
                            }
                        })
                    }
                    _viewData.healthPercent = (Math.round(_viewData?.health / _viewData?.maxHealth) * 100);
                    _viewData.kiPercent = (Math.round(_viewData?.ki / _viewData?.maxKi) * 100);
                    _viewData.expPercent = (Math.round((_viewData?.experience - _viewData?.levelExperience) / (_viewData?.needExperience - _viewData?.levelExperience) * 100));
                    setViewData(_viewData);
                    setFoundChar(true);
                    setFailedPrompt("");
                }
            }
            catch(err)
            {
                if (err?.response?.status === 429){
                    setDisplayError("Slow down! Try again in a few minutes.");
                } else {
                    setDisplayError("Failed to search character.");
                }
                setFoundChar(false);
                setLoading(false);
                setFailedPrompt(searchName);
            };
        }
        if (hasSearchInput.context && ((!fetched.context) || (searchName?.length > 0 && searchName != viewData?.name) || (searchId > 0 && searchId != viewData?.id)))
            fetch_data();
        else if (!hasSearchInput.context){
            setViewData(null);
            setFoundChar(false);
            setLoading(false);
            setSearchInput("");
        }
    }, [searchParams]);

    useEffect(()=> {
        if (failedPrompt?.length > 0 && failedPrompt !== searchInput)
            setDisplayError("");
    }, [searchInput]);

    return (
        <ThemeProvider theme={theme}>
        {(!foundChar) ?
            <Container component="main" maxWidth="xs">
                <Box
                component='form'
                autoComplete='on' 
                onSubmit={onSubmitSearch}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                            minHeight: '150px' 
                        }}>
                        <Typography
                            variant="h4"
                            margin="15px"
                            color="white" 
                            textAlign="center">Search</Typography>
                        <Stack justifyItems="center"
                            alignItems="center">
                        {displayError?.length > 0 ? 
                            <TextField variant="filled"
                                error
                                id="searchField"
                                label="Search Character"
                                color="primary"
                                autoComplete='off'
                                helperText={displayError}
                                value={searchInput}
                                onInput={e=>setSearchInput(e.target.value)} 
                                sx={{
                                    maxWidth: '350px',
                                    input:{color:'white', textAlign:'center'}
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                      <Search color='primary'/>
                                    </InputAdornment>
                                  }}/>
                            :
                            <TextField variant="filled"
                                id="searchField"
                                label="Search Character"
                                color="primary"
                                autoComplete='off'
                                value={searchInput}
                                onInput={e=>setSearchInput(e.target.value)}
                                sx={{
                                    maxWidth: '350px',
                                    input:{color:'white', textAlign:'center'}
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                      <Search color='primary'/>
                                    </InputAdornment>
                                  }}/>
                            }
                            {(loading) ?
                            <CircularProgress color="secondary" />
                            :null
                            }
                        </Stack>
                    </Paper>
                </Box>
            </Container>
        : 
        <Container component="main" maxWidth="xs" sx={{marginTop:'15px', marginBottom:'15px'}}>
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                            minHeight: '350px',
                            paddingBottom: '10px'
                        }}>
                        <Typography
                            variant="h4"
                            margin="15px"
                            color="white" 
                            textAlign="center">{viewData?.name}</Typography>
                        <Box margin="10px">
                        <Grid container 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={1}>
                            <Grid item xs="auto">
                                <Stack direction="column" spacing={0.5} alignItems="flex-start">
                                    <Typography
                                        variant="h7"
                                        color="white">{"Level: "+ viewData?.level}</Typography>
                                    <Typography
                                        variant="h7"
                                        color="white">{"Vocation: "+ viewData?.vocation}</Typography>
                                    <Typography
                                        variant="h7"
                                        color="white">{"Title: "+ viewData?.title}</Typography>
                                    <Typography
                                        variant="h7"
                                        color="white">{"Guild: None"}</Typography>
                                    <Typography
                                        variant="h7"
                                        color="white">{"Last Login: "+ viewData?.lastLogin}</Typography>
                                    <Typography
                                        variant="h7"
                                        color="white">{"Created: "+ viewData?.created}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction="column" alignItems="center" spacing={0.6}>
                                    {viewData?.suspended ? 
                                    <Box
                                        component="img"
                                        sx={{
                                        height: 100,
                                        width: 100
                                        }}
                                        style={{filter: "grayscale(90%)"}}
                                        alt="Image"
                                        src={viewData?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : viewData?.imageSource}
                                    />
                                    : <Box
                                        component="img"
                                        sx={{
                                        height: 100,
                                        width: 100
                                        }}
                                        alt="Image"
                                        src={viewData?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : viewData?.imageSource}
                                    />}
                                    <Tooltip title={"Health: "+viewData?.health+"/"+viewData?.maxHealth} TransitionComponent={Zoom} arrow>
                                    <HPBar sx={{
                                        width:'100px'
                                        }} variant='determinate' value={viewData?.healthPercent}/>
                                    </Tooltip>
                                    <Tooltip title={"Ki: "+viewData?.ki+"/"+viewData?.maxKi} TransitionComponent={Zoom} arrow>
                                    <KIBar sx={{
                                        width:'100px'
                                        }} variant='determinate' value={viewData?.kiPercent}/>
                                    </Tooltip>
                                    <Tooltip title={"Experience: "+viewData?.experience+" ("+viewData?.expPercent+"%)"} TransitionComponent={Zoom} arrow>
                                    <EXPBar sx={{
                                        width:'100px'
                                        }} variant='determinate' value={viewData?.expPercent}/>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                        </Grid>
                        {viewData?.suspended ? 
                        <Alert variant="filled" sx={{marginX:'15px', marginBottom:'5px', marginTop:'5px'}} severity='error'>
                            This character has an active suspension.
                        </Alert>
                        : null}
                        <Accordion sx={{marginTop:'10px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography color='primary'>Skills</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={1}>
                                    <Grid item xs="auto">
                                        <Stack direction="column" spacing={0.5} alignItems="center">
                                            <Typography
                                                variant="h7"
                                                color="white">{"Ki Level: "+ viewData?.kiLevel}</Typography>
                                            <Typography
                                                variant="h7"
                                                color="white">{"Train Points: "+ viewData?.trainPoints}</Typography>
                                            <Typography
                                                variant="h7"
                                                color="white">{"Attack Speed: "+ viewData?.attackSpeed}</Typography>
                                            <Typography
                                                variant="h7"
                                                color="white">{"Sword Fighting: "+ viewData?.swordFighting}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Stack direction="column" spacing={0.5} alignItems="center">
                                            <Typography
                                                variant="h7"
                                                color="white">{"Strength: "+ viewData?.strength}</Typography>
                                            <Typography
                                                variant="h7"
                                                color="white">{"Ki Blasting: "+ viewData?.kiBlasting}</Typography>
                                            <Typography
                                                variant="h7"
                                                color="white">{"Defense: "+ viewData?.defense}</Typography>
                                            <Typography
                                                variant="h7"
                                                color="white">{"Energy: "+ viewData?.energy}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography color='primary'>Account Characters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {viewData?.account?.length > 0 ?
                                <Grid container direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}>
                                    {viewData?.account.map((char, i) =>
                                    <Grid key={i} item xs="auto">
                                        <Paper elevation={16} onClick={()=>{
                                            navigate('/search?name='+char?.name); 
                                            setSearchInput(char?.name)
                                            }} sx={{
                                                cursor: 'pointer',
                                                opacity: 0.85,
                                                ':hover': {
                                                    opacity: 1,
                                                }
                                            }}>
                                            <Stack direction="column" alignItems="center" spacing={0.2} marginX='10px' minWidth='80px'>
                                                <Box
                                                    marginTop='10px'
                                                    component="img"
                                                    sx={{
                                                    height: 48,
                                                    width: 48
                                                    }}
                                                    alt="Image"
                                                    src={char?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : char?.imageSource}
                                                />
                                                <Typography
                                                    variant="h7"
                                                    color="white">{char?.name}</Typography>
                                                <Typography
                                                    variant="h8"
                                                    color="lightgray">{"Level " + char?.level}</Typography>
                                            </Stack>
                                        </Paper>
                                    </Grid>
                                    )}
                                </Grid>: <Typography color='primary' variant='h7'>This is the only character on the account.</Typography>}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography color='primary'>Death List</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                { viewData?.deaths?.length > 0 ?
                                <Stack direction="column"
                                    alignItems="center">
                                    {viewData?.deaths.map((death, i) =>
                                    <Grid key={i} container 
                                    direction="row"
                                     spacing={1}
                                     alignItems="center"
                                    justifyContent="space-between">
                                        <Grid item xs="auto">
                                            {
                                                (death?.killerId > -1) ? 
                                                <Typography
                                                onClick={() => {navigate('/search?name='+death?.killerName); setSearchInput(death?.killerName)}}
                                                sx={{fontSize:'14px', cursor:'pointer'}}
                                                variant="h7"
                                                color="lightgray">{"Died at level " + death?.level + " to " + death?.killerName + "."}</Typography>
                                                :
                                                <Typography
                                                sx={{fontSize:'14px'}}
                                                variant="h7"
                                                color="lightgray">{"Died at level " + death?.level + " to " + death?.killerName + "."}</Typography>
                                            }
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography
                                                sx={{fontSize:'14px'}}
                                                variant="h7"
                                                color="lightgray">{death?.date}</Typography>
                                        </Grid>
                                    </Grid>
                                    )}
                                </Stack>: <Typography color='primary' variant='h7'>This character has no deaths.</Typography>}
                            </AccordionDetails>
                        </Accordion>
                        { viewData?.bans?.length > 0 ?
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography color='primary'>Rule Violations</Typography>
                            </AccordionSummary>
                            <AccordionDetails justifyContent="flex-start" alignItems="flex-start">
                                <Stack justifyContent="flex-start" alignItems="scretch" textAlign="left">
                                    <Stack sx={{maxHeight:'40vh', overflowY:'scroll'}}>
                                    <Divider sx={{marginY:'5px'}}/>
                                    {viewData?.bans.map((m, i) =>
                                    <Stack key={i} alignItems="scretch">
                                        <Grid container alignItems="scretch" justifyContent="space-between">
                                            <Grid item xs="auto">
                                                <Stack>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs="auto">
                                                            <Typography variant="h6" sx={{color:ACTIONS_COLOR[m?.action]}}>{ACTIONS_NAME[m?.action]}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {m?.isActive ?
                                                        (m?.action == 3 ? 
                                                        <Typography variant="h7" sx={{color:'#805a40', fontSize:'16px'}}>Permanent Suspension</Typography>
                                                        : <Typography variant="h7" sx={{color:'#ba5c56', fontSize:'16px'}}>Expires in {getCountdownString(m?.expiration)}</Typography>)
                                                        : <Typography variant="h7" sx={{color:'#8f8f8f', fontSize:'16px'}}>Expired or lifted</Typography>
                                                    }
                                                    
                                                    <Typography variant="h7" sx={{color:'lightgray', fontSize:'14px', maxWidth:'200px'}}>{m?.reason}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Stack justifyContent="flex-end" alignItems="flex-end">
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                        height: 50,
                                                        width: 50
                                                        }}
                                                        alt="Image"
                                                        src={m?.banner?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : m?.banner?.imageSource} />
                                                    <Typography variant="h7" sx={{maxWidth:'100px', textAlign:'right'}}>Assigned by {m?.banner?.name}</Typography>
                                                    <Typography variant="h7" sx={{maxWidth:'100px', textAlign:'right'}}>{m?.added}</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{marginY:'5px'}}/>
                                    </Stack>
                                    )}
                                </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion> : null}
                        </Box>
                    </Paper>
                </Box>
            </Container>}
        </ThemeProvider>
    );
}

export default CharacterView;