import React from "react";
const useGlobalAlert = () => {
  const [alertType, setAlertType] = React.useState("info");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertEmail, setAlertEmail] = React.useState(false);
  const [closedAlert, setClosedAlert] = React.useState(false);

  const changeAlert = (type, message, email, closed) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertEmail(email);
    setClosedAlert(closed);
  };
  return [{message: alertMessage, type: alertType, email: alertEmail, closed: closedAlert, active: alertMessage?.length > 0 && !closedAlert}, changeAlert];
};
export default useGlobalAlert;