import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import HighscoresIcon from '@mui/icons-material/Leaderboard';
import ForumIcon from '@mui/icons-material/Forum';
import MyAccountIcon from '@mui/icons-material/AccountCircle';
import CreateCharacterIcon from '@mui/icons-material/GroupAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import WikiIcon from '@mui/icons-material/Language';
import Paper from '@mui/material/Paper';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import PeopleIcon from '@mui/icons-material/People';
import Stack from '@mui/material/Stack';
import DiscordIcon from '../Icons/discord-small.png';
import Avatar from '@mui/material/Avatar';
import GroupIcon from '@mui/icons-material/Group';
import StarsIcon from '@mui/icons-material/Stars';

import logo_icon from '../Icons/logo.png'

import { useNavigate } from 'react-router-dom';
import useAccount from '../Hooks/useAccount';
import useLogout from '../Hooks/useLogout';
import {useEffect, useState} from 'react';
import { Expand, Logout } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import useCache from '../Hooks/useCache';
import axios from '../Api/axios';
import useGlobalAlert from '../Hooks/useGlobalAlert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FriendsPanel from '../Components/FriendsPanel';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

const ONLINE_ENDPOINT = "game/characters/online";



function CustomNavbar() {
    const cache = useCache();
    const [alert, changeAlert] = useGlobalAlert();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElCommunity, setAnchorElCommunity] = React.useState(null);
    const [anchorElAccount, setAnchorElAccount] = React.useState(null);

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenCommunityMenu = (event) => {
      setAnchorElCommunity(event.currentTarget);
    };
    const handleOpenAccountMenu = (event) => {
      setAnchorElAccount(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
    const handleCloseCommunityMenu = () => {
      setAnchorElCommunity(null);
    };
    const handleCloseAccountMenu = () => {
      setAnchorElAccount(null);
    };

    const navigate = useNavigate();
    const accountInfo = useAccount();
    const logout = useLogout();
    const [logged, setLogged] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");

    const [online, setOnline] = useState([]);

    const fetchOnline = () => {
      const fetch_data = async () => {
        try {
            if (cache.valid("dbw-cache-online")){
                const data = cache.get("dbw-cache-online");
                setOnline(data);
            } else {
                const response = await axios.get(ONLINE_ENDPOINT);
                let pData = response?.data;
                setOnline(pData);
                cache.fetch("dbw-cache-online", pData, 60*1000)
            }
        }
        catch(err)
        {
            if (!err?.response) {
                if (cache.valid("dbw-cache-online")){
                  setOnline(cache.get("dbw-cache-online"));
                } else{
                  setOnline([]);
                }
                changeAlert("warning", "API is currently experiencing downtime. The site might not function properly.", false, false);
            } else if (err.response?.status === 429) {
                if (cache.valid("dbw-cache-online")){
                  setOnline(cache.get("dbw-cache-online"));
                } else{
                  setOnline([]);
                }
            }
        };
      }
      fetch_data();
    }

    useEffect(() => {
      fetchOnline();
      const interval = setInterval(() => {
        fetchOnline();
      }, 60000);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    
    useEffect(() => {
        const accInfo = accountInfo.getInfo();
        setLogged(accInfo.logged);
        if (accInfo.logged){
            setDisplayName(accInfo?.data?.displayName);
            setEmail(accInfo?.data?.email);
            if (!accInfo?.data?.confirmed && !alert?.closed){
              changeAlert("warning", "Your account's email is not confirmed. Confirmation has been sent on "+accInfo?.data?.email+".", true, false);
            }
        } else{
          if (alert?.email){
            changeAlert("", "", false, false);
          }
        }
    }, [accountInfo]);
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          {alert?.active ?
          <Alert severity={alert?.type} variant="filled" action={alert?.email ? <div>
              <Button color="inherit" size="small" onClick={()=>{navigate('/manageaccount', {
              replace:true, state:{
                email:email,
                confirmEmail:true
              }})}}>RESEND</Button>
              <Button color="inherit" size="small" onClick={()=>{changeAlert("", "", false, true);}}>CLOSE</Button>
              </div> : null} onClose={() => {changeAlert("", "", false, true);}}>
            {alert?.message}
          </Alert> : null}
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {navigate('/', {replace:true})}}>
              <Box
                component="img"
                sx={{
                height: 48,
                }}
                alt="DBWOTS"
                src={logo_icon}
            />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem key="Home" onClick={()=> {
                  handleCloseNavMenu();
                  navigate('/home');
                }}>
                  <HomeIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Home</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem key="Search Character" onClick={() => {
                  handleCloseNavMenu();
                  navigate('/search', {replace:true})
                  }}>
                  <SearchIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Search</Typography>
                </MenuItem>
                <MenuItem key="Highscores" onClick={() => {
                  handleCloseNavMenu();
                  navigate('/highscores');
                  }}>
                  <HighscoresIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Highscores</Typography>
                </MenuItem>
                <MenuItem key="Online" onClick={() => {
                  handleCloseNavMenu();
                  navigate('/online');
                  }}>
                  <GroupIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Online Players</Typography>
                </MenuItem>
                <MenuItem key="Team" onClick={() => {
                  handleCloseNavMenu();
                  navigate('/team');
                  }}>
                  <StarsIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">DBW Team</Typography>
                </MenuItem>
                <MenuItem key="Vote" onClick={() => {
                  handleCloseNavMenu();
                  navigate('/vote');
                  }}>
                  <ThumbsUpDownIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Votes</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem key="Forum" onClick={() => {
                  handleCloseNavMenu();
                  window.open('https://dbwots.pl/index.php/community/');
                  }}>
                  <ForumIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Forum</Typography>
                </MenuItem>
                <MenuItem key="Wiki" onClick={() => {
                  handleCloseNavMenu();
                  window.open('https://dbwots.pl/');
                  }}>
                  <WikiIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Wiki</Typography>
                </MenuItem>
                <MenuItem key="Discord" onClick={() => {
                  handleCloseNavMenu();
                  window.open('https://discord.gg/8t5rkpNu2a');
                  }}>
                  <Avatar src={DiscordIcon} sx={{width:'24px', height:'auto', color:'white'}}/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Discord</Typography>
                </MenuItem>
              </Menu>
              <Menu
                id="menu-community"
                anchorEl={anchorElCommunity}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElCommunity)}
                onClose={handleCloseCommunityMenu}
                sx={{
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <MenuItem key="Search Character" onClick={() => {
                  handleCloseCommunityMenu();
                  navigate('/search', {replace:true});
                  }}>
                  <SearchIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Search</Typography>
                </MenuItem>
                <MenuItem key="Highscores" onClick={() => {
                  handleCloseCommunityMenu();
                  navigate('/highscores');
                  }}>
                  <HighscoresIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Highscores</Typography>
                </MenuItem>
                <MenuItem key="Online" onClick={() => {
                  handleCloseCommunityMenu();
                  navigate('/online');
                  }}>
                  <GroupIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Online Players</Typography>
                </MenuItem>
                <MenuItem key="Team" onClick={() => {
                  handleCloseCommunityMenu();
                  navigate('/team');
                  }}>
                  <StarsIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">DBW Team</Typography>
                </MenuItem>
                <MenuItem key="Vote" onClick={() => {
                  handleCloseCommunityMenu();
                  navigate('/vote');
                  }}>
                  <ThumbsUpDownIcon/>
                  <Typography color="inherit" marginLeft="6px" textAlign="center">Votes</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ flexGrow:1, display: {xs: 'none', md:'flex' }}}>
                <Button key="Home"
                onClick={()=>{
                  navigate('/home');
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                  Home
                </Button>
                <Button key="Community"
                onClick={handleOpenCommunityMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs="auto">
                      Community
                    </Grid>
                    <Grid item xs="auto">
                      <ExpandMoreIcon sx={{marginTop:'5px', height:'20px'}}/>
                    </Grid>
                  </Grid>
                  
                </Button>
                <Button key="Wiki"
                onClick={()=>{
                  window.open('https://dbwots.pl/');
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                  Wiki
                </Button>
                <Button key="Forum"
                onClick={()=>{
                  window.open('https://dbwots.pl/index.php/community/');
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                  Forum
                </Button>
                <Button key="Discord"
                onClick={()=>{
                  window.open('https://discord.gg/8t5rkpNu2a');
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                  Discord
                </Button>
            </Box>
            <Menu
              id="menu-account"
              anchorEl={anchorElAccount}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElAccount)}
              onClose={handleCloseAccountMenu}
              sx={{
                display: { xs: 'block', md: 'block' },
              }}
            >
              <MenuItem key="MyAccount" onClick={() => {
                handleCloseAccountMenu();
                navigate('/account'); 
                }}>
                <MyAccountIcon/>
                <Typography color="inherit" marginLeft="6px" textAlign="center">My Characters</Typography>
              </MenuItem>
              <MenuItem key="Create Character" onClick={() => {
                handleCloseAccountMenu();
                navigate('/createcharacter');
                }}>
                <CreateCharacterIcon/>
                <Typography color="inherit" marginLeft="6px" textAlign="center">Create Character</Typography>
              </MenuItem>
              <MenuItem key="ManageAccount" onClick={() => {
                handleCloseAccountMenu();
                navigate('/manageaccount'); 
                }}>
                <ManageAccountsIcon/>
                <Typography color="inherit" marginLeft="6px" textAlign="center">Manage Account</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem key="Logout" onClick={() => {
                handleCloseAccountMenu();
                logout();
                }}>
                <LogoutIcon/>
                <Typography color="inherit" marginLeft="6px" textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
            {
            logged && displayName?.length ? 
            <Button color="inherit" onClick={handleOpenAccountMenu}>{displayName}</Button>
            : <Button color="inherit" onClick={() => {
                navigate('/login');
            }}>Login</Button>
            }
          </Toolbar>
        </AppBar>
        {logged ? <FriendsPanel/> : null}
        <Box sx={{ position: 'fixed', bottom: 0, left: 0 }}>
            <Stack orientation="column">
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item md="auto">
                  <SignalCellularAltIcon sx={{color:'lightgreen'}}/>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="h7" color="lightgreen" sx={{marginLeft:'3px'}}>Online</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item md="auto">
                  <PeopleIcon sx={{color:'lightgreen'}}/>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="h7" color="lightgreen" sx={{marginLeft:'3px'}}>{online?.length} players</Typography>
                </Grid>
              </Grid>
            </Stack>
        </Box>
      </Box>
    );
}

export default CustomNavbar;