import axios from '../Api/axios';
import useAccount from '../Hooks/useAccount';

const useRefreshToken = () => {
    const account = useAccount();
    const refresh = async () => {
        try {
            var form = new FormData()
            form.append('DeviceIdentifier', 'react-app')
            form.append('ClientVersion', 'debug')
            const response = await axios.put('/auth/refresh', form, {
                withCredentials: true
            });
            return response?.data?.accessToken;
        }
        catch {
            console.log('please log in again');
            account.saveLogout();
        }
    }
    return refresh;
};

export default useRefreshToken;