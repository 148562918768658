const useCache = () => {

    return {
        valid: (key) => {
            const entry = localStorage.getItem(key);
            if (entry){
                const value = JSON.parse(entry);
                if (value?.old && typeof(value?.old) === "number"
                    && value?.data){
                    const lifetime = Number(value?.old);
                    return lifetime >= Date.now();
                } else{
                    return false;
                }
            }
            else
                return false;
        },
        get: (key) => {
            const entry = localStorage.getItem(key);
            if (entry){
                const value = JSON.parse(entry);
                if (value?.old && typeof(value?.old) === "number"
                    && value?.data){
                    return value?.data;
                } else{
                    return {};
                }
            }
            else
                return {};
        },
        fetch: (key, data, expire) => {
            localStorage.setItem(key, JSON.stringify({
                old: Date.now() + expire,
                data: data
            }));
        },
        clear: (key) => {
            localStorage.removeItem(key);
        }
    }
}

export default useCache;