import {useState, useEffect, useRef} from 'react';
import useCache from '../Hooks/useCache';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import logo_icon from '../Icons/logo.png'


//import '../Styles/Highscores.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import {useNavigate} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import {getCountdownString} from '../Pages/Home';
import DiscordIcon from '../Icons/discord-small.png';
import TimerIcon from '@mui/icons-material/Timer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CommentIcon from '@mui/icons-material/Comment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@mui/material';


const theme = createTheme();

const VOTES_ENDPOINT = "home/vote"

const getAnswerOption = (answer) => {
    if (answer == "yes"){
        return 0;
    } else if (answer == "no"){
        return 1;
    } else {
        return -1;
    }
}

const getOptionAnswer = (option) => {
    if (option == 0)
        return "yes";
    else if (option == 1)
        return "no";
    else
        return "undef";
}

const Vote = () => {
    const [data, setData] = useState([]);
    const [answer, setAnswer] = useState('undef');
    const fetched = useRef(false);
    const axiosPrivate = useAxiosPrivate();
    const cache = useCache();
    const displayError = useRef(false);
    const navigate = useNavigate();
    const [type, setType] = useState(0);
    const currentVote = useRef(null);
    const [commentInput, setCommentInput] = useState("");
    const [loading, setLoading] = useState(true);

    const [openVoteDialog, setOpenVoteDialog] = useState(false);

    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleOpenDialog = (voteIndex) => {
        currentVote.context = data[voteIndex];
        setAnswer('undef');
        setOpenVoteDialog(true);
    }
    const handleChange = (event, newAnswer) => {
        setAnswer(newAnswer);
      };

    const submitVote = async () => {
        if (answer == "undef" || currentVote.context == null){
            setErrorMessage("Please select the answer.");
            setErrorOpen(true);
            return;
        }
        setOpenVoteDialog(false);
        try {
            var form = new FormData()
            form.append('SessionId', currentVote.context?.id);
            form.append('Option', getAnswerOption(answer));
            form.append('Comment', commentInput);
            const response = await axiosPrivate.put(VOTES_ENDPOINT,
                form);
            if (response?.status === 200){
                setErrorOpen(false);
                setCommentInput("");
                setSuccessMessage("Your vote has been submitted. Thank you!");
                setSuccessOpen(true);
                fetchVotes(true);
            }
        } catch (err) {
            if (!err?.response) {
                setErrorMessage('Server is not responding. Try again later.');
                setErrorOpen(true);
            } else if (err.response?.status === 429) {
                setErrorMessage('Too many requests. Please slow down.');
                setErrorOpen(true);
            } else if (err.response?.status === 401) {
                navigate('/login', {
                    replace:true});
            } else {
                const errorCode = err?.response?.data?.code
                const errorMessage = err?.response?.data?.message
                setSuccessOpen(false);
                setErrorMessage(errorMessage);
                setErrorOpen(true);
            }
        }
    }

    const fetchVotes = (force) => {
        const fetch_data = async (force) => {
            fetched.context = true;
            try {
                if (cache.valid("dbw-cache-vote") && !force){
                    const data = cache.get("dbw-cache-vote");
                    setData(data);
                    displayError.context = false;
                } else {
                    setLoading(true);
                    const response = await axiosPrivate.get(VOTES_ENDPOINT);
                    setLoading(false);
                    let pData = response?.data;
                    if (pData?.length > 0)
                    {
                        pData?.forEach((it, i) => {
                            pData[i].start = new Date(it?.start + 'Z');
                            pData[i].end = new Date(it?.end + 'Z');
                            if (it?.end){
                                pData[i].countdown = new Date(it?.end).getTime();
                            }
                        })
                    }
                    setData(pData);
                    displayError.context = false;
                    cache.fetch("dbw-cache-vote", pData, 180*1000)
                }
            }
            catch(err)
            {
                setLoading(false);
                if (cache.valid("dbw-cache-vote")){
                    setData(cache.get("dbw-cache-vote"));
                    displayError.context = false;
                } else{
                    displayError.context = true;
                }
            };
        }
        if (!fetched.context || force)
            fetch_data(force);
        else
            setLoading(false);
    }

    useEffect(()=>{
        fetchVotes(false);
    })

    useEffect(()=> {
        const interval = setInterval(() => {
            setData(prevState=> {
                const list = prevState.map(d =>{
                    d.countdownString = getCountdownString(d?.countdown)
                    return d;
                });
                return list;
            });
        }, 1000);
    }, [])
    
    return (
            <Container component="main" maxWidth="m" sx={{paddingTop:`calc(120px - 7vh)`}}>
                <Snackbar open={successOpen} autoHideDuration={10000} onClose={(e, r) => {
                    if (r === "clickaway") {
                        return;
                    }
                    setSuccessOpen(false);
                }}>
                    <Alert onClose={()=>{setSuccessOpen(false);}} variant="filled" severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={10000} onClose={(e, r) => {
                    if (r === "clickaway") {
                        return;
                    }
                    setErrorOpen(false);
                }}>
                    <Alert onClose={()=>{setErrorOpen(false);}} variant="filled" severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                {data?.length > 0 ?
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center'
                }}>
                    <Typography variant="h4"
                                margin="15px"
                                color="white"
                                sx={{fontSize:'27px'}} >Available Vote Sessions</Typography>
                    
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        {data?.map((record, i)=> 
                            <Grid item xs="auto" key={i}>
                                <Paper sx={{padding:'8px', borderRadius:'10px'}}>
                                    <Typography 
                                    variant="h4"
                                    margin="5px"
                                    color="lightgray"
                                    sx={{fontSize:'15px'}}>
                                        {record?.name}
                                    </Typography>
                                    <Divider color="secondary" sx={{marginX:'10px', marginY:'5px'}}/>
                                    <Box
                                        component="img"
                                        sx={{
                                        height: 128,
                                        }}
                                        alt="Image"
                                        src={record?.imageUrl === "" ? logo_icon : record?.imageUrl}
                                        />
                                    <Divider color="secondary" sx={{marginX:'10px', marginY:'5px'}}/>
                                    <Grid container justifyContent="space-around" alignItems="space-around">
                                        <Grid item xs="auto">
                                            <Typography
                                            variant="h4"
                                            color="lightgray"
                                            sx={{fontSize:'15px', marginTop:'6px'}}>Discord:</Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Grid container alignItems="center" justifyContent="flex-end" spacing={0.5}>
                                                <Grid item xs="auto">
                                                    <Typography variant="h7">{record?.contact}</Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Avatar src={record?.contactUrl} sx={{width:'30px', height:'30px'}}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider color="secondary" sx={{marginX:'10px', marginY:'5px'}}/>
                                    <Typography
                                    variant="h4"
                                    margin="10px"
                                    color="white"
                                    textAlign="center"
                                    maxWidth={200}
                                    sx={{fontSize:'17px'}}>
                                        {record?.description}
                                    </Typography>
                                    {record?.alreadyVoted ? 
                                    <Grid container alignItems="center" justifyContent="center" spacing={0.5}>
                                        <Grid item xs="auto">
                                            <CheckIcon sx={{color:'lightgreen', width:'20px'}}/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography variant="h7" color="lightgreen" sx={{fontSize:'14px'}}>You've submitted your vote '{getOptionAnswer(record?.votedOption)}'.</Typography>
                                        </Grid>
                                    </Grid>
                                     : (!record?.canVote ? 
                                    <Tooltip title={"Reason: "+record?.reason} TransitionComponent={Zoom} arrow>
                                    <Grid container alignItems="center" justifyContent="center" spacing={0.5}>
                                        <Grid item xs="auto">
                                            <ErrorIcon sx={{color:'red', width:'20px'}}/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography maxWidth={200} variant="h7" color="red" sx={{fontSize:'14px'}}>You are not allowed to vote.</Typography>
                                        </Grid>
                                    </Grid>
                                    </Tooltip>
                                     : <Button sx={{color:'lightblue'}} onClick={()=>{handleOpenDialog(i)}}>
                                        Click To Vote
                                    </Button>
                                    
                                    )}
                                    
                                    <Divider color="secondary" sx={{marginX:'10px', marginY:'5px'}}/>
                                    <Grid container alignItems="center" justifyContent="flex-end" spacing={0.5}>
                                        <Grid item xs="auto">
                                            <TimerIcon color="lightgray" small/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography
                                            variant="h4"
                                            margin="10px"
                                            color="lightgray"
                                            maxWidth={200}
                                            sx={{fontSize:'15px', marginLeft:'2px'}}>
                                                Vote ends in {record?.countdownString}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        
                    </Grid>
                    <Stack sx={{marginTop:'10px', marginBottom:'30px', marginX:'25px'}} justifyContent="center" alignItems="center">
                        <Alert variant="filled" sx={{maxWidth:'700px'}} severity="info">Vote submissions are anonymous. Using multiple accounts owned by the same player is not allowed. Session criterias and final results will be posted on our forum and discord.
                        </Alert>
                    </Stack>
                    
                </Box>
                : (loading ?
                    <CircularProgress color="secondary" />
                    : <Stack sx={{marginTop:'10px', marginBottom:'30px', marginX:'25px'}} justifyContent="center" alignItems="center">
                        <Alert variant="filled" sx={{maxWidth:'700px'}} severity="warning">There are currently no active vote sessions.
                        </Alert>
                      </Stack>
                    )}
                <Dialog open={openVoteDialog} onClose={()=>{setOpenVoteDialog(false);}}>
                    <DialogTitle>Submit Vote</DialogTitle>
                    <DialogContent justifyContent="center" alignItems="center">
                        <Stack orientation="column" justifyContent="center" alignItems="center">
                        <Typography variant="h4"
                                    margin="10px"
                                    color="white"
                                    maxWidth={400}
                                    sx={{fontSize:'17px'}}>{currentVote.context?.description}</Typography>
                        <Box sx={{marginTop:'10px'}} justifyContent="center" alignItems="center">
                        <ToggleButtonGroup
                            value={answer}
                            exclusive
                            onChange={handleChange}
                            aria-label="Answer">
                        <ToggleButton sx={{width:'100px', color:'#8f8f8f'}} value="yes">Yes</ToggleButton>
                        <ToggleButton sx={{width:'100px', color:'#8f8f8f'}} value="no">No</ToggleButton>
                        </ToggleButtonGroup>
                        </Box>
                        <TextField variant="filled"
                                id="commentField"
                                multiline
                                label="Optional Comment"
                                color="primary"
                                autoComplete='off'
                                helperText="Please keep the comments short and concise."
                                value={commentInput}
                                onInput={e=>setCommentInput(e.target.value)} 
                                inputProps={{
                                    maxLength: 250,
                                    style: {
                                        minHeight: '50px',
                                        maxHeight: '300px'
                                    }
                                }}
                                sx={{
                                    maxWidth: '350px',
                                    width: '350px',
                                    maxHeight: '400px',
                                    input:{color:'white', textAlign:'center'},
                                    marginTop:'20px'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                      <CommentIcon color='primary'/>
                                    </InputAdornment>
                                  }}/>
                        </Stack>
                        
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setOpenVoteDialog(false);}}>Close</Button>
                        <Button onClick={()=>{submitVote();}}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </Container>
    );
}

export default Vote;