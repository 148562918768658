import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = () => {
    return {
        palette: {
            mode: 'light',
            primary: {
            main: '#6f5cf3',
            },
            secondary: {
            main: '#b6ade2',
            },
            background: {
            default: '#1c1c4c',
            paper: '#2b2b7d',
            },
            text: {
            primary: 'rgba(255,255,255,0.87)',
            secondary: 'rgba(255,255,255,0.87)',
            disabled: 'rgba(146,146,146,0.38)',
            hint: '#dad2ff',
            },
            divider: 'rgba(158,158,158,0.12)',
        }
    };
}