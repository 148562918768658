import { axiosPrivate } from "../Api/axios";
import AuthContext from '../Context/AuthContext';
import { useEffect, useContext } from "react";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, setAuth } = useContext(AuthContext);

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            async config => {
                if (auth === null) { // user has JUST refreshed
                    console.log('no access token found, attempting refresh')
                    const newAccessToken = await refresh();
                    config.headers['Authorize'] = newAccessToken;
                    setAuth({
                        AccessToken: newAccessToken
                    });
                }
                else if (!config.headers['Authorize']) {
                    config.headers['Authorize'] = auth?.AccessToken;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorize'] = newAccessToken;
                    setAuth({
                        AccessToken: newAccessToken
                    });
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, setAuth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;