import axios from 'axios';

const BASE_URL = process.env.NODE_ENV === 'dev' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD

const BASE_TEST = "http://localhost:5000/api/"
const BASE_NORMAL = "http://dbwots.ddns.net:82/api/"

export default axios.create({
    //baseURL: 'http://146.59.80.164:82/api/'
    baseURL: BASE_NORMAL,
    headers: {'Content-Type': 'multipart/form-data'}
});

export const axiosPrivate = axios.create({
    baseURL: BASE_NORMAL,
    headers: {'Content-Type': 'multipart/form-data'},
    withCredentials: true
});