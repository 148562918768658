const STORAGE_ACCOUNT_INFO = "dbw-account-state";

const useAccount = () => {

    return {
        saveLogin: (displayName, access, email, confirmed) => {
            localStorage.setItem(STORAGE_ACCOUNT_INFO, JSON.stringify({
                logged: true,
                data: {
                    loggedAt: Date.now(),
                    displayName: displayName,
                    access: access,
                    email: email,
                    confirmed: confirmed
                }
            }));
        },
        updateConfirmed: (info, confirmed) => {
            if (info?.logged) {
                localStorage.setItem(STORAGE_ACCOUNT_INFO, JSON.stringify({
                    logged: info?.logged,
                    data: {
                        loggedAt: info?.data?.loggedAt,
                        displayName: info?.data?.displayName,
                        access: info?.data?.access,
                        email: info?.data?.email,
                        confirmed: confirmed
                    }
                }));
            }
        },
        saveLogout: () => {
            localStorage.removeItem(STORAGE_ACCOUNT_INFO);
        },
        getInfo: () => {
            const entry = localStorage.getItem(STORAGE_ACCOUNT_INFO);
            if (entry){
                const value = JSON.parse(entry);
                if (value?.logged){
                    return value;
                } else{
                    return {logged:false};
                }
            }
            else
                return {logged:false};
        }
    }
}

export default useAccount;