import {useState, useEffect, useRef} from 'react';
import useCache from '../Hooks/useCache';
import axios from '../Api/axios';


//import '../Styles/Highscores.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import {useNavigate} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const theme = createTheme();

const HIGHSCORES_ENDPOINT = "game/highscores"

const UNKNOWN_OUTFIT_SRC = "https://dbwots.ddns.net/outfits/dynamic/unknown.png"

const types = [
    "Level",
    "Ki Level",
    "Attack Speed",
    "Train Points",
    "Sword Fighting",
    "Strength",
    "Ki Blasting",
    "Defense",
    "Energy",
    "Fishing"
]

const Highscores = () => {
    const [data, setData] = useState([]);
    const fetched = useRef(false);
    const cache = useCache();
    const displayError = useRef(false);
    const navigate = useNavigate();
    const [type, setType] = useState(0);

    const fetchHighscores = (newType) => {
        const fetch_data = async (newType) => {
            fetched.context = true;
            try {
                if (cache.valid("dbw-cache-highscores"+newType)){
                    const data = cache.get("dbw-cache-highscores"+newType);
                    setData(data);
                    displayError.context = false;
                } else {
                    const response = await axios.get(HIGHSCORES_ENDPOINT +"?type="+ newType);
                    setData(response?.data);
                    displayError.context = false;
                    cache.fetch("dbw-cache-highscores"+newType, response?.data, 180*1000)
                }
            }
            catch(err)
            {
                if (cache.valid("dbw-cache-highscores"+newType)){
                    setData(cache.get("dbw-cache-highscores"+newType));
                    displayError.context = false;
                } else{
                    displayError.context = true;
                }
            };
        }
        if (!fetched.context)
            fetch_data(newType);
    }

    const handleTypeChange = (e) => {
        setType(e.target.value);
        fetched.context = false;
        fetchHighscores(e.target.value);
    }

    useEffect(()=>{
        fetchHighscores(type);
    })
    
    return (
            <Container component="main" maxWidth="xs" sx={{paddingTop:`calc(120px - 7vh)`}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center'
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                        }}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs="auto">
                                <Typography
                                variant="h4"
                                margin="15px"
                                color="white"
                                sx={{fontSize:'27px'}} 
                                textAlign="center">Highscores for</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <FormControl sx={{ m: 1, marginLeft:'5px', minWidth: 120 }}>
                                    <Select
                                    value={type}
                                    onChange={handleTypeChange}
                                    >
                                    {types.map((t, i) => 
                                        <MenuItem key={i} value={i}>{t}</MenuItem>)
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {displayError.context ? <Box>
                            <WarningIcon sx={{
                                color: 'darkred'
                            }}/>
                            <Typography color="darkred"
                                textAlign="Center"
                                margin="10px"
                                variant="h5">Failed to fetch data.</Typography>
                        </Box>
                         : 
                        <Box>
                            <Grid container 
                                        direction="row"
                                        justifyContent="space-around"
                                        alignItems="center"
                                        spacing={0}>
                                <Grid item xs={1}>
                                    <Typography 
                                        color="white"
                                        margin="6px"
                                        textAlign="center"
                                        justifyContent="center"
                                        variant="h6">#</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography 
                                        color="white"
                                        margin="6px"
                                        textAlign="center"
                                        justifyContent="center"
                                        variant="h6">Outfit</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography 
                                        color="white"
                                        margin="6px"
                                        textAlign="center"
                                        justifyContent="center"
                                        sx={{marginRight:'15px'}}
                                        variant="h6">Name</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography 
                                        color="white"
                                        margin="6px"
                                        textAlign="center"
                                        justifyContent="center"
                                        sx={{marginRight:'15px'}}
                                        variant="h6">{types[type]}</Typography>
                                </Grid>
                            </Grid>
                            <Stack spacing={2}
                                    divider={<Divider/>}
                                    sx={{
                                        maxHeight: '65vh',
                                        overflowY: 'scroll'
                                    }}>
                                {data.map((record, i) =>
                                <Box key={i} 
                                onClick={() => {
                                    navigate('/search?name='+record?.name)
                                }}
                                sx={{
                                    cursor: 'pointer'
                                }}>
                                    <Tooltip title={"🔍 Go to: "+ record?.name} placement="left" TransitionComponent={Zoom} arrow>
                                    <Grid container 
                                        direction="row"
                                        justifyContent="space-around"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                            transition: "padding 0.25s",
                                            "&:hover": {
                                                paddingY: '5px'
                                            }
                                        }}>
                                        <Grid item xs={1}>
                                            <Typography 
                                                color="darkgray"
                                                margin="3px"
                                                marginLeft="8px"
                                                textAlign="center"
                                                justifyContent="center"
                                                variant="h6">{(i+1)+"."}</Typography>    
                                        </Grid>
                                        <Grid item xs={1}>
                                        <Box
                                            component="img"
                                            sx={{
                                            height: 64,
                                            }}
                                            alt="Image"
                                            src={record?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : record?.imageSource}
                                        />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography 
                                                color="lightgray"
                                                margin="3px"
                                                textAlign="center"
                                                justifyContent="center"
                                                variant="h6">{record?.name}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography 
                                                color="darkgray"
                                                margin="3px"
                                                textAlign="center"
                                                justifyContent="center"
                                                variant="h6">{record?.level}</Typography>
                                        </Grid>
                                    </Grid>
                                    </Tooltip>
                                </Box>
                                )}
                            </Stack>
                        </Box>}
                    </Paper>
                </Box>
            </Container>
    );
}

export default Highscores;