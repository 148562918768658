import {useState, useEffect, useRef} from 'react';
import useCache from '../Hooks/useCache';
import axios from '../Api/axios';


//import '../Styles/Highscores.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import {useNavigate} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import DiscordIcon from '../Icons/discord-small.png';
import Chip from '@mui/material/Chip';
import {LightenDarkenColor} from '../Pages/Home';
import {StyledBadge, StyledBadgeOffline} from '../Components/FriendsPanel';


const theme = createTheme();

const STAFF_ENDPOINT = "game/characters/staff";

const UNKNOWN_OUTFIT_SRC = "https://dbwots.ddns.net/outfits/dynamic/unknown.png"

const GROUP_COLORS = {
    "Admin" : '#c4463b',
    "Senior Gamemaster" : '#3d3bc4',
    "Gamemaster" : '#545454',
    "Banner" : '#7054c4',
    "Counsellor" : '#54bdc4',
    "Tutor" : '#26a334'
}

const STAFF = [
    {
        name: 'Villy',
        avatar: 'https://cdn.discordapp.com/avatars/266692059820195840/30babaccceb5821dfe7a09984b1bb1b8.png?size=2048',
        position: 'Admin',
        positionColor: '#c4463b',
        otherRoles: ['Dev', 'Technical'],
        since: 'Nov 2018',
        discord: 'xvilly'
    },
    {
        name: 'Titan',
        avatar: 'https://cdn.discordapp.com/avatars/319448382286594050/a6ed66e978838296090002e605f3da2b.png?size=2048',
        position: 'Admin',
        positionColor: '#c4463b',
        otherRoles: ['Dev', 'Technical'],
        since: 'Nov 2018',
        discord: 'titan1179'
    },
    {
        name: 'Elendil',
        avatar: 'https://media.discordapp.net/attachments/964168911740366873/1140329282829299782/78cbf576a1d4ae08a5f2c88c1fcc0e5e.png?size=2048',
        position: 'Banner',
        positionColor: '#7054c4',
        otherRoles: [],
        since: 'Aug 2023',
        discord: 'elendil1111'
    },
    {
        name: 'Toshiro',
        avatar: 'https://media.discordapp.net/attachments/964168911740366873/1140328809439170610/9c9fb183d5e4f7b62249d1fcdccec360.png?size=2048',
        position: 'Counsellor',
        positionColor: '#54bdc4',
        otherRoles: ['In Game: Kremowkarz'],
        since: 'Aug 2023',
        discord: 'toshiro__'
    },
    {
        name: 'Graff',
        avatar: 'https://media.discordapp.net/attachments/964168911740366873/1157025448727937064/9cdc117b9b24b5cf6e2d0c376c067475.png?size=2048',
        position: 'Tutor',
        positionColor: '#26a334',
        otherRoles: ['In Game: Shion'],
        since: 'Aug 2023',
        discord: 'graff__'
    },
]

const TECHNICAL = [
    {
        name: 'Poncjusz',
        avatar: 'https://media.discordapp.net/attachments/964168911740366873/1130947407509725264/d470e210cb46f908a2f7a2d883df6d96.png',
        position: 'Technical',
        positionColor: '#98c73c',
        otherRoles: ['Tester'],
        since: 'Jan 2021',
        discord: '.poncjusz'
    },
    {
        name: 'Calewien',
        avatar: 'https://cdn.discordapp.com/avatars/271218617029361674/0829ff8dd6780bb7e79300ac7dcb6756.png?size=2048',
        position: 'Technical',
        positionColor: '#98c73c',
        otherRoles: ['Tester'],
        since: 'Nov 2021',
        discord: 'calewien'
    },
    {
        name: 'Xanaved',
        avatar: 'https://cdn.discordapp.com/avatars/300350137077858306/b98da9a5c89a2fcda7bfe7bb886dd125.png?size=2048',
        position: 'Technical',
        positionColor: '#98c73c',
        otherRoles: ['Tester'],
        since: 'Dec 2022',
        discord: 'ronaldoluisnazario'
    }
]

const Team = () => {
    const fetched = useRef(false);
    const cache = useCache();
    const displayError = useRef(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [openStaffDialog, setOpenStaffDialog] = useState(false);

    const fetchStaff = () => {
        const fetch_data = async () => {
          try {
              if (cache.valid("dbw-cache-staff")){
                  const data = cache.get("dbw-cache-staff");
                  setData(data);
              } else {
                  const response = await axios.get(STAFF_ENDPOINT);
                  let pData = response?.data;
                  setData(pData);
                  cache.fetch("dbw-cache-staff", pData, 60*1000)
              }
          }
          catch(err)
          {
              if (!err?.response) {
                  if (cache.valid("dbw-cache-staff")){
                    setData(cache.get("dbw-cache-staff"));
                  } else{
                    setData([]);
                  }
              } else if (err.response?.status === 429) {
                  if (cache.valid("dbw-cache-staff")){
                    setData(cache.get("dbw-cache-staff"));
                  } else{
                    setData([]);
                  }
              }
          };
        }
        fetch_data();
      }

    useEffect(() => {
        fetchStaff();
    }, [])
    
    return (
            <Container component="main" maxWidth="xs" sx={{paddingTop:`calc(120px - 7vh)`}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center'
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                            padding: '10px'
                        }}>
                            <Typography
                            variant="h4"
                            margin="15px"
                            color="white" 
                            textAlign="center">DBW Team</Typography>
                            <Accordion sx={{backgroundColor:'#1c1c4c'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography color='secondary'>Staff Members</Typography>
                                </AccordionSummary>
                                <AccordionDetails justifyContent="flex-start" alignItems="flex-start">
                                    <Stack justifyContent="flex-start" alignItems="scretch" textAlign="left">
                                        <Typography variant="h7" sx={{color:'lightgray'}}>
                                            Staff focuses on in-game support and makes sure players are not breaking the rules.
                                        </Typography>
                                        <Divider sx={{marginY:'5px'}}/>
                                        <Stack sx={{maxHeight:'40vh', overflowY:'scroll'}}>
                                            {STAFF.map((m, i) =>
                                            <Stack key={i} alignItems="scretch">
                                                <Grid container alignItems="scretch" justifyContent="space-between">
                                                    <Grid item xs="auto">
                                                        <Stack>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs="auto">
                                                                    <Typography variant="h6">{m?.name}</Typography>
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    <Tooltip title="Other roles">
                                                                        <Grid container>
                                                                            {m.otherRoles.map((role, i)=>
                                                                            <Grid key={i} item xs="auto"> 
                                                                                <Chip label={role} sx={{color:'darkgray', fontSize:'10px'}} />
                                                                            </Grid>)}
                                                                        </Grid>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                            
                                                            <Typography variant="h7" sx={{color:m?.positionColor, fontSize:'16px'}}>{m?.position}</Typography>
                                                            <Typography variant="h7" sx={{color:'lightgray', fontSize:'14px'}}>Member since {m?.since}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Stack justifyContent="flex-end" alignItems="flex-end">
                                                            <Avatar src={m?.avatar} sx={{width:'60px', height:'auto'}}/>
                                                            <Grid container alignItems="center" justifyContent="flex-start" spacing={0.5}>
                                                                <Grid item xs="auto">
                                                                    <Typography variant="h7">{m?.discord}</Typography>
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    <Avatar src={DiscordIcon} sx={{width:'24px', height:'auto', color:'white'}}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{marginY:'5px'}}/>
                                            </Stack>
                                            )}
                                        </Stack>
                                        
                                        <Button onClick={()=>{setOpenStaffDialog(true);}}>
                                            View All Staff Characters
                                        </Button>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{backgroundColor:'#1c1c4c', marginBottom:'5px'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography color='secondary'>Technical Support</Typography>
                                </AccordionSummary>
                                <AccordionDetails justifyContent="flex-start" alignItems="flex-start">
                                    <Stack justifyContent="flex-start" alignItems="scretch" textAlign="left">
                                        <Typography variant="h7" sx={{color:'lightgray'}}>
                                            Technical Support helps with balancing systems, planning new content and testing upcoming features.
                                        </Typography>
                                        <Divider sx={{marginY:'5px'}}/>
                                        <Stack sx={{maxHeight:'40vh', overflowY:'scroll'}}>
                                            {TECHNICAL.map((m, i) =>
                                            <Stack key={i} alignItems="scretch">
                                                <Grid container alignItems="scretch" justifyContent="space-between">
                                                    <Grid item xs="auto">
                                                        <Stack>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs="auto">
                                                                    <Typography variant="h6">{m?.name}</Typography>
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    <Tooltip title="Other roles">
                                                                        <Grid container>
                                                                            {m.otherRoles.map((role, i)=>
                                                                            <Grid key={i} item xs="auto"> 
                                                                                <Chip label={role} sx={{color:'darkgray', fontSize:'10px'}} />
                                                                            </Grid>)}
                                                                        </Grid>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                            
                                                            <Typography variant="h7" sx={{color:m?.positionColor, fontSize:'16px'}}>{m?.position}</Typography>
                                                            <Typography variant="h7" sx={{color:'lightgray', fontSize:'14px'}}>Member since {m?.since}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Stack justifyContent="flex-end" alignItems="flex-end">
                                                            <Avatar src={m?.avatar} sx={{width:'60px', height:'auto'}}/>
                                                            <Grid container alignItems="center" justifyContent="flex-start" spacing={0.5}>
                                                                <Grid item xs="auto">
                                                                    <Typography variant="h7" sx={{fontSize:m?.discord?.length > 18 ? '11px' : '14px'}}>{m?.discord}</Typography>
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    <Avatar src={DiscordIcon} sx={{width:'24px', height:'auto', color:'white'}}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{marginY:'5px'}}/>
                                            </Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Dialog open={openStaffDialog}>
                                <DialogTitle>Staff Characters</DialogTitle>
                                <DialogContent>
                                {data?.length < 1 ? <Typography variant="h7" textAlign="center" sx={{fontFamily:'Dosis', fontSize:'16px', color:'lightgray'}}>There are no staff characters.</Typography> : null}
                                    <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{
                                                maxHeight: '65vh',
                                                overflowY: 'scroll',
                                                padding: '10px'
                                            }}>
                                        {data.map((char, i)=> 
                                        <Grid key={i} item xs="auto">
                                            <Paper elevation={16} sx={{
                                                        opacity: 0.85,
                                                        transition: "opacity 0.25s",
                                                        backgroundColor: GROUP_COLORS[char?.group] !== null ? LightenDarkenColor(GROUP_COLORS[char?.group], -95) : '#1c1c4c',
                                                        ':hover': {
                                                            opacity: 1
                                                        }
                                                    }}>
                                                    <Stack direction="column" alignItems="center" spacing={0.2} marginX='10px' minWidth='80px'>
                                                        {char?.online ?
                                                        <StyledBadge
                                                        overlap="circular"
                                                        marginTop='10px'
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot">
                                                            <Box component="img"
                                                                sx={{
                                                                height: 48,
                                                                width: 48
                                                                }}
                                                                alt="Image"
                                                                src={char?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : char?.imageSource}
                                                                />
                                                        </StyledBadge> :
                                                        <StyledBadgeOffline
                                                        overlap="circular"
                                                        marginTop='10px'
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot">
                                                            <Box component="img"
                                                                sx={{
                                                                height: 48,
                                                                width: 48
                                                                }}
                                                                alt="Image"
                                                                src={char?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : char?.imageSource}
                                                                />
                                                        </StyledBadgeOffline>
                                                        }
                                                         
                                                        <Typography
                                                            variant="h7"
                                                            color="white">{char?.name}</Typography>
                                                        <Typography
                                                            variant="h8"
                                                            sx={{color:GROUP_COLORS[char?.group] !== null ? LightenDarkenColor(GROUP_COLORS[char?.group], 95) : 'lightgray'}}>{char?.group}</Typography>
                                                    </Stack>
                                                </Paper>
                                        </Grid>
                                        )}
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={()=>{setOpenStaffDialog(false);}}>Close</Button>
                                </DialogActions>
                            </Dialog>
                            
                    </Paper>
                </Box>
            </Container>
    );
}

export default Team;