import useAccount from "../Hooks/useAccount";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import {useContext} from 'react';
import AuthContext from '../Context/AuthContext';
import {useNavigate} from 'react-router-dom';
import useCache from '../Hooks/useCache';

const LOGOUT_ENDPOINT = "auth/logout";

const useLogout = () => {
    const {setAuth} = useContext(AuthContext);
    const account = useAccount();
    const cache = useCache();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const sendLogout = async () => {
        try{
            const response = await axiosPrivate.delete(LOGOUT_ENDPOINT);
            if (response?.data?.code === -1){
                account.saveLogout();
                setAuth(null);
                navigate('/', {replace:true});
                cache.clear("dbw-cache-charlist");
                cache.clear("dbw-cache-friends");
                cache.clear("dbw-cache-account");
                cache.clear("dbw-cache-refcode");
            }
        }
        catch(err){
            return false;
        }
    }
    return sendLogout;
}

export default useLogout;