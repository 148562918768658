import React from 'react'
import {useState, useEffect} from 'react'
import logo_icon from '../Icons/logo.png'
import discord_icon from '../Icons/discord.png'
import "../Styles/Start.css"
import { useNavigate } from 'react-router-dom';
import useAccount from '../Hooks/useAccount';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { SendTimeExtension } from '@mui/icons-material';
import axios from '../Api/axios';
import email_success from '../Icons/email-success.png';

export const Landing = () => {
  const accountInfo = useAccount();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [emailVerified, setEmailVerified] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");

  const [oldPwd, setOldPwd] = useState("");
  const [pwd, setPwd] = useState("");

  const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

  

  const isAnyDialogOpened = () => {
    return openPasswordDialog;
  }

  const sendPasswordChange = async () => {
    setSuccessMessage("");
    try {
      var verify = searchParams.get("verify");
      var accName = searchParams.get("authaccount");
      var form = new FormData();
      if (oldPwd?.length < 1 || pwd?.length < 1) {
        setErrorMessage("Password fields are required.");
        return;
      }
      // Authentication Form
      form.append('AccountName', accName)
      form.append('Password', oldPwd)
      form.append('DeviceIdentifier', 'react-app')
      form.append('ClientVersion', 'debug')
      form.append('NewPassword', pwd);
      form.append('Verify', verify);
      const response = await axiosPrivate.patch("auth/account/password",
      form, {withCredentials:true});
      let pData = response?.data;
      if (pData?.code === -1){
        const accInfo = accountInfo.getInfo();
        if (accInfo?.logged) {
          navigate('/manageaccount', {replace:true, state:{
            message:'Password has been changed.',
            messageType:'success'
          }});
        } else {
          setErrorMessage("");
          setPwd("");
          setOldPwd("");
          setSuccessMessage("Password changed! You may now close this page.");
        }
      }
    }
    catch (err) {
      if (!err?.response){
        setErrorMessage("Server is not responding.");
      } 
      else if (err?.response?.status == 429) {
        setErrorMessage("Too many requests. Wait a few minutes.");
      } else {
        if (err?.response?.data?.code) {
          setErrorMessage(err?.response?.data?.message);
        }
      }
    }
  }

  const sendEmailVerification = async () => {
    try {
      var verify = searchParams.get("verify");
      const response = await axios.get("auth/account/email?verify="+(verify?.length > 0 ? verify : "empty_field"));
      let pData = response?.data;
      if (pData?.code === -1){
        setEmailVerified(true);
        setEmailMessage("Your email has been verified successfully! See you in game!");
      }
    }
    catch (err) {
        if (err?.response?.data?.code){
            setEmailVerified(false);
            setEmailMessage(err?.response?.data?.message);
        }
    }
  }

  const openDialog = (dialogMode) => {
    setOpenPasswordDialog(false);
    switch (dialogMode) {
      case "changepassword":
        setOpenPasswordDialog(true);
        break;
      case "email":
        sendEmailVerification();
        break;
    }
  }

  useEffect(()=>{
    let dialogMode = searchParams.get("action");
    openDialog(dialogMode);
  }, [searchParams]);

  return (
    <Container component="main" maxWidth="xs">
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center'
            }}>
            {!isAnyDialogOpened() && !(emailMessage?.length > 0) ? <Stack orientation="column" justifyItems="center" alignItems="center">
              <Typography variant="h7" sx={{fontFamily:'Dosis', fontSize:'17px'}}>Sending request..</Typography>
              <CircularProgress sx={{marginY:'10px'}}/>
            </Stack> : (emailMessage?.length > 0 ? 
            <Stack orientation="column" justifyItems="center" alignItems="center">
              {emailVerified ? <Box
                  component="img"
                  sx={{
                  width: '40vw',
                  maxWidth: '300px'
                  }}
                  alt="Image"
                  src={email_success}
              /> : null}
              <Alert variant="filled" severity={emailVerified ? "success" : "error"}>{emailMessage}</Alert>
              {emailVerified ? <Button sx={{marginY:'5px'}} onClick={()=>{navigate('/manageaccount')}}>GO TO ACCOUNT</Button> :
                <Button sx={{marginY:'5px'}} onClick={()=>{navigate('/home')}}>HOME</Button>}
            </Stack>
              : null)}
            <Dialog open={openPasswordDialog}>
              <DialogTitle>Change Password</DialogTitle>
              <DialogContent>
                  <Stack orientation="column" justifyContent="center" alignItems="scretch">
                    <TextField 
                      required
                      variant="outlined"
                      id="oldPasswordField"
                      autoComplete="off"
                      label="Old Password"
                      color="primary"
                      value={oldPwd}
                      onInput={e=>setOldPwd(e.target.value)} 
                      sx={{
                          marginTop: '15px',
                          marginBottom: '15px',
                          input:{color:'white', textAlign:'left'}
                      }}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                      endAdornment: <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          >
                          {showPassword ? <VisibilityOff sx={{color:'white'}}/> : <Visibility sx={{color:'white'}}/>}
                          </IconButton>
                      </InputAdornment>
                      }}/>
                    <TextField 
                      required
                      variant="outlined"
                      id="passwordField"
                      autoComplete="off"
                      label="New Password"
                      color="primary"
                      value={pwd}
                      onInput={e=>setPwd(e.target.value)} 
                      sx={{
                          marginTop: '15px',
                          marginBottom: '15px',
                          input:{color:'white', textAlign:'left'}
                      }}
                      type={showPassword ? 'text' : 'password'}/>
                      {errorMessage?.length > 0 ?
                      <Alert variant="filled" severity="error">{errorMessage}</Alert> : null}
                      {successMessage?.length > 0 ?
                      <Alert variant="filled" severity="success">{successMessage}</Alert> : null}
                  </Stack>
              </DialogContent>
              <DialogActions>
                  <Button onClick={()=>{navigate('/home');}}>Cancel</Button>
                  <Button onClick={()=>{sendPasswordChange();}}>Change</Button>
              </DialogActions>
          </Dialog>
        </Box>
    </Container>
  )
}
