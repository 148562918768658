
import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import useCache from '../Hooks/useCache';
import axios from '../Api/axios';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import "../Styles/FriendsPanel.css";
import Stack from '@mui/material/Stack';

const drawerBleeding = 40;

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

export const StyledBadgeOffline = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#7a7a7a',
      color: '#7a7a7a',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        border: '1px solid currentColor',
        content: '""',
      },
    },
  }));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  }));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.secondary.light,
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const FRIENDS_ENDPOINT = "game/account/friends";

const getAgoString = (date) => {

    let countDown = new Date().getTime() - date;
    // calculate time left
    const years = Math.floor(countDown / (1000 * 60 * 60 * 24 * 30 * 12));
    const months =  Math.floor(countDown / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    
    if (years > 0)
        return years+" year"+(years!==1?"s":"")+" ago";
    else if (months > 0)
        return months+" month"+(months!==1?"s":"")+" ago";
    else if (days > 0)
        return days+" day"+(days!==1?"s":"")+" ago";
    else if(hours > 0)
        return hours+" hour"+(hours!==1?"s":"")+" ago";
    else if(minutes > 0)
        return minutes+" minute"+(minutes!==1?"s":"")+" ago";
    else
        return seconds+" second"+(seconds!==1?"s":"")+" ago";
  };

function FriendsPanel() {
  const [open, setOpen] = React.useState(false);
  const container = window !== undefined ? () => window.document.body : undefined;
  const [friends, setFriends] = React.useState([]);
  const [friendsCount, setFriendsCount] = React.useState(0);

  const cache = useCache();
  const axiosPrivate = useAxiosPrivate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const fetchFriends = () => {
    const fetch_data = async () => {
      try {
          if (cache.valid("dbw-cache-friends")){
              const data = cache.get("dbw-cache-friends");
              setFriends(data);
          } else {
              const response = await axiosPrivate.get(FRIENDS_ENDPOINT);
              let pData = response?.data;
              var count = 0;
              pData?.forEach((it, i)=> {
                pData[i].lastSeen = new Date(it?.lastSeen + 'Z').getTime();
                pData[i].lastSeenString = getAgoString(pData[i].lastSeen);
                var displayString = "";
                var charactersOnline = pData[i]?.charactersOnline?.length;
                if (charactersOnline > 0){
                    count += 1;
                    displayString = "Currently playing on "
                    pData[i]?.charactersOnline?.forEach((char, i)=> {
                        displayString += char?.name + " (Level " + char?.level + ")";
                        if (i !== (charactersOnline - 1)) {
                            displayString += ", ";
                        }
                    });
                } else{
                    displayString = "Last seen "+pData[i].lastSeenString;
                }
                pData[i].displayString = displayString;
              });
              setFriendsCount(count);
              setFriends(pData);
              cache.fetch("dbw-cache-friends", pData, 120*1000)
          }
      }
      catch(err)
      {
          if (!err?.response) {
              if (cache.valid("dbw-cache-friends")){
                setFriends(cache.get("dbw-cache-friends"));
              } else{
                setFriends([]);
              }
          } else if (err.response?.status === 429) {
              if (cache.valid("dbw-cache-friends")){
                setFriends(cache.get("dbw-cache-friends"));
              } else{
                setFriends([]);
              }
          }
      };
    }
    fetch_data();
  }

  React.useEffect(()=> {
        fetchFriends();
      const interval = setInterval(() => {
        fetchFriends();
      }, 120000);
    
      return () => clearInterval(interval);
  }, [])

  return (<div>
    <Global
    styles={{
        '.MuiDrawer-root > .MuiPaper-root': {
        height: `calc(30% - ${drawerBleeding}px)`,
        overflow: 'visible',
        boxShadow:'none',
        backgroundColor: 'transparent',
        width: `calc(15% + 220px)`,
        marginLeft: `calc(85% - 220px)`
        },
    }}
    />
    <SwipeableDrawer
    BackdropProps={{invisible:true}}
    container={container}
    anchor="bottom"
    open={open}
    onClose={toggleDrawer(false)}
    onOpen={toggleDrawer(true)}
    swipeAreaWidth={drawerBleeding}
    disableSwipeToOpen={false}
    ModalProps={{
        keepMounted: true,
    }}
    >
        <StyledBox
            sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 0,
            visibility: 'visible',
            right: 0,
            width: '220px',
            height: '100%'
            }}
        >
            <Grid container justifyContent="flex-start" alignItems="center">
                {friends?.length > 0 ? <Grid item xs="auto">
                    <IconButton onClick={toggleDrawer(!open)} sx={{pointerEvents:'all', color:'white'}}> 
                        {!open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </IconButton>
                </Grid> : null}
                <Grid item xs="auto">
                    <Diversity3Icon sx={{marginTop:'5px', marginLeft:'5px'}}/>
                </Grid>
                <Grid item xs="auto">
                    <Typography variant="h7" sx={{ fontSize:'15px', color: 'white', marginLeft:'6px' }}>{friendsCount} friend{friendsCount !==1 ? "s" :""} online</Typography>
                </Grid>
            </Grid>
        </StyledBox>
        <StyledBox
            sx={{
            position: 'absolute',
            right: '0',
            width: '220px',
            height: '100%',
            overflowY: 'scroll',
            }}
        >
            {friends.map((friend, i)=>
            <div key={i}>
                <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item xs="auto">
                        {friend?.charactersOnline?.length === 2 ?
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        sx={{marginRight:'3px'}}
                        variant="dot">
                            <Avatar alt="Image" src={friend?.charactersOnline[0]?.imageSource} />
                            <Avatar alt="Image" src={friend?.charactersOnline[1]?.imageSource} sx={{marginLeft:'-30px'}} />
                        </StyledBadge>
                        : (friend?.charactersOnline?.length === 1 ?
                            <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            sx={{marginRight:'3px'}}
                            variant="dot">
                                <Avatar alt="Image" src={friend?.charactersOnline[0]?.imageSource} sx={{marginX:'5px'}} />
                            </StyledBadge>
                            : <StyledBadgeOffline
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            sx={{marginRight:'3px'}}
                            variant="dot">
                                <AccountCircleIcon fontSize="large" sx={{marginX:'7px'}}/>
                            </StyledBadgeOffline>)}
                    </Grid>
                    <Grid item xs="auto">
                        <Stack width="157px">
                            <Typography variant="h7" sx={{fontSize:'17px', fontFamily:'Dosis', color:friend?.charactersOnline?.length>0?"#c4ff87":"white"}}>{friend?.displayName}</Typography>
                            <div id="scroll-container" style={{height:'20px'}}>
                                <div id={friend?.charactersOnline?.length>0?"scroll-text":"none"} style={{fontFamily:'Dosis', color:friend?.charactersOnline?.length>0?"#9cd463":"lightgray", fontSize:'15px', animationDuration:0.4*friend?.displayString?.length+'s'}}>{friend?.displayString}</div>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
                {i !== (friends?.length - 1) ? <Divider sx={{marginY:'2px', marginX:'2px', height:'2px', color:'white'}}/> : null}
            </div>
            )}
        </StyledBox>
    </SwipeableDrawer>
    </div>);
}

export default FriendsPanel;