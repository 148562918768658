import {useState, useEffect, useRef} from 'react';
import useCache from '../Hooks/useCache';
import axios from '../Api/axios';


//import '../Styles/Highscores.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import {useNavigate} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const theme = createTheme();

const ONLINE_ENDPOINT = "game/characters/online";

const UNKNOWN_OUTFIT_SRC = "https://dbwots.ddns.net/outfits/dynamic/unknown.png"

const OnlinePlayers = () => {
    const fetched = useRef(false);
    const cache = useCache();
    const displayError = useRef(false);
    const navigate = useNavigate();
    const [online, setOnline] = useState([]);

    const fetchOnline = () => {
        const fetch_data = async () => {
          try {
              if (cache.valid("dbw-cache-online")){
                  const data = cache.get("dbw-cache-online");
                  setOnline(data);
              } else {
                  const response = await axios.get(ONLINE_ENDPOINT);
                  let pData = response?.data;
                  setOnline(pData);
                  cache.fetch("dbw-cache-online", pData, 60*1000)
              }
          }
          catch(err)
          {
              if (!err?.response) {
                  if (cache.valid("dbw-cache-online")){
                    setOnline(cache.get("dbw-cache-online"));
                  } else{
                    setOnline([]);
                  }
              } else if (err.response?.status === 429) {
                  if (cache.valid("dbw-cache-online")){
                    setOnline(cache.get("dbw-cache-online"));
                  } else{
                    setOnline([]);
                  }
              }
          };
        }
        fetch_data();
      }

    useEffect(() => {
        fetchOnline();
        const interval = setInterval(() => {
          fetchOnline();
        }, 60000);
      
        return () => clearInterval(interval);
    }, [])
    
    return (
            <Container component="main" maxWidth="xs" sx={{paddingTop:`calc(120px - 7vh)`}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center'
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                            padding: '5px'
                        }}>
                            <Typography
                            variant="h4"
                            margin="15px"
                            color="white" 
                            textAlign="center">Online Players</Typography>
                            {online?.length < 1 ? <Typography variant="h7" textAlign="center" sx={{fontFamily:'Dosis', fontSize:'16px', color:'lightgray'}}>There are no players online at the moment.</Typography> : null}
                            <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{
                                        maxHeight: '65vh',
                                        overflowY: 'scroll',
                                        padding: '10px'
                                    }}>
                                {online.map((char, i)=> 
                                <Grid key={i} item xs="auto">
                                    <Paper elevation={16} onClick={()=>{
                                            navigate('/search?name='+char?.name); 
                                            }} sx={{
                                                cursor: 'pointer',
                                                opacity: 0.85,
                                                transition: "opacity 0.25s",
                                                ':hover': {
                                                    opacity: 1
                                                }
                                            }}>
                                            <Stack direction="column" alignItems="center" spacing={0.2} marginX='10px' minWidth='80px'>
                                                <Box
                                                    marginTop='10px'
                                                    component="img"
                                                    sx={{
                                                    height: 48,
                                                    width: 48
                                                    }}
                                                    alt="Image"
                                                    src={char?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : char?.imageSource}
                                                />
                                                <Typography
                                                    variant="h7"
                                                    color="white">{char?.name}</Typography>
                                                <Typography
                                                    variant="h8"
                                                    color="lightgray">{"Level " + char?.level}</Typography>
                                            </Stack>
                                        </Paper>
                                </Grid>
                                )}
                            </Grid>
                    </Paper>
                </Box>
            </Container>
    );
}

export default OnlinePlayers;