import React from 'react'
import logo_icon from '../Icons/logo.png'
import discord_icon from '../Icons/discord.png'
import "../Styles/Start.css"
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

export const Start = () => {
  const navigate = useNavigate();

  function onClickDiscord(){
      window.open("https://discord.com/invite/qfhUpw3WfS")
  }


  return (
    // <div className="start-page-container">
    //   <img className='logo-img' src={logo_icon} alt=""/>
    //   <button className="btn" onClick={() => {navigate('/login', {replace:true})}}>Login</button>
    //   <button className="btn" onClick={() => {navigate('/createaccount', {replace:true})}}>Create Account</button>
    //   <input type="image" onClick={onClickDiscord} 
    //   src={discord_icon} width="100" height="100" alt="Join us on Discord!"/>
    // </div>
    <Container component="main" maxWidth="xs">
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center'
            }}>
            <Stack orientation="column" justifyItems="center" alignItems="center">
              <Box
                  component="img"
                  onClick={()=>{navigate('/')}}
                  sx={{
                  width: '40vw',
                  maxWidth: '300px',
                  cursor: 'pointer'
                  }}
                  alt="Image"
                  src={logo_icon}
              />
              <Button sx={{marginY:'5px', marginTop:'20px'}} onClick={()=>{navigate('/login')}}>LOG IN</Button>
              <Button sx={{marginY:'5px'}} onClick={()=>{navigate('/createaccount')}}>CREATE NEW ACCOUNT</Button>
              <Button sx={{marginY:'5px'}} onClick={()=>{
                    window.open('https://dbwots.pl/');
                  }}>VISIT OUR WIKI</Button>
              <Stack orientation="row">
                <Tooltip title={"Join us on discord!"} placement="bottom" TransitionComponent={Fade} arrow>
                  <Box
                      component="img"
                      onClick={()=>{window.open("https://discord.gg/8t5rkpNu2a")}}
                      sx={{
                      width: '12vw',
                      maxWidth: '80px',
                      cursor: 'pointer'
                      }}
                      alt="Image"
                      src={discord_icon}
                  />
                </Tooltip>
              </Stack>
            </Stack>
        </Box>
    </Container>
  )
}
