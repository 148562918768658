import React, {useEffect, useState, useRef} from 'react';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Styles/Account.css';
import useCache from '../Hooks/useCache';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const CHARACTERS_ENDPOINT = "game/account/characters"

const UNKNOWN_OUTFIT_SRC = "http://146.59.80.164/outfits/dynamic/unknown.png"

const ConvertZenniToString = (zenni) => {
    if (zenni >= 1000000000)
        return (Math.round(zenni / 1000000000 * 100) / 100).toString() + "b";
    else if (zenni >= 1000000)
        return (Math.round(zenni / 1000000 * 100) / 100).toString() + "m";
    else if (zenni >= 1000)
        return (Math.round(zenni / 1000 * 100) / 100).toString() + "k";
    return zenni.toString();
}

export const Account = () => {
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState([]);
    const fetched = useRef(false);
    const cache = useCache();
    const [message, setMessage] = useState('');

    useEffect(()=> {
        if (location.state?.characterCreated){
            fetched.context = false;
            fetchCharactersList(true);
            setSuccessMessage("Character created successfully.");
            setSuccessOpen(true);
        }
    });

    const fetchCharactersList = (force) => {
        const fetch_data = async (force) => {
            fetched.context = true;
            try {
                if (cache.valid("dbw-cache-charlist") && !force){
                    const data = cache.get("dbw-cache-charlist");
                    setData(data);
                } else {
                    const response = await axiosPrivate.get(CHARACTERS_ENDPOINT);
                    let pData = response?.data;
                    if (pData?.length > 0)
                    {
                        pData?.forEach((it, i) => {
                            pData[i].balance = ConvertZenniToString(it?.balance);
                        })
                    }
                    setData(pData);
                    cache.fetch("dbw-cache-charlist", pData, 45*1000)
                }
            }
            catch(err)
            {
                if (!err?.response) {
                    if (cache.valid("dbw-cache-charlist")){
                        setData(cache.get("dbw-cache-charlist"));
                    } else{
                        navigate('/login', {replace:true});
                    }
                } else if (err.response?.status === 429) {
                    if (cache.valid("dbw-cache-charlist")){
                        setData(cache.get("dbw-cache-charlist"));
                    } else{
                        navigate('/login', {replace:true});
                    }
                } else if (err.response?.status === 401) {
                    navigate('/login', {replace:true});
                } else {
                    navigate('/login', {replace:true});
                }
            };

            if (location.state?.characterCreated){
                navigate('/account', {replace:true});
            }
        }
        if (!fetched.context)
            fetch_data(force);
    }

    useEffect(() => {
        fetchCharactersList(false);
    });

  return (
    <Container component="main" maxWidth="xs">
        <Snackbar open={successOpen} autoHideDuration={10000} onClose={(e, r) => {
            if (r === "clickaway") {
                return;
            }
            setSuccessOpen(false);
        }}>
            <Alert onClose={()=>{setSuccessOpen(false);}} variant="filled" severity="success" sx={{ width: '100%' }}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Box 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center'
        }}>
            <Paper elevation={16}
                sx={{
                    borderRadius: 10,
                }}>
                <Typography
                    variant="h4"
                    margin="15px"
                    color="white" 
                    textAlign="center">My Account</Typography>
                    {message?.length > 0 ? <Box>
                            <Typography color="darkred"
                                textAlign="Center"
                                margin="10px"
                                variant="h5">{message}</Typography>
                        </Box>
                         : 
                        <Box>
                            {data?.length > 0 ?
                            <Stack spacing={2}
                                    divider={<Divider/>}
                                    sx={{
                                        maxHeight: '45vh',
                                        overflowY: 'scroll'
                                    }}>
                                {data.map((user, i) =>
                                <Box key={i} 
                                onClick={() => {
                                    navigate('/search?name='+user?.name)
                                }}
                                sx={{
                                    cursor: 'pointer'
                                }}>
                                    <Tooltip title={"🔍 Go to: "+ user?.name} placement="left" TransitionComponent={Zoom} arrow>
                                    <Grid container 
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                            transition: "padding 0.25s",
                                            "&:hover": {
                                                paddingY: '5px'
                                            }
                                        }}>
                                        <Grid item xs="auto">
                                            <img className="character-img" src={user?.imageSource === "unknown" ? UNKNOWN_OUTFIT_SRC : user?.imageSource} width='64' height='64' alt=''/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Stack orientation="column">
                                                <Typography 
                                                    color="lightgray"
                                                    margin="3px"
                                                    textAlign="center"
                                                    justifyContent="center"
                                                    variant="h6">{user?.name + " (Lv. "+ user?.level + ")"}</Typography>
                                                <Typography 
                                                    color="lightgray"
                                                    margin="3px"
                                                    textAlign="center"
                                                    justifyContent="center"
                                                    sx={{fontSize:'18px', color:'darkgray'}}
                                                    variant="h6">{"$ "+user?.balance}</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    </Tooltip>
                                </Box>
                                )}
                            </Stack>
                            :
                            <Box>
                            <Typography color="darkgray"
                            textAlign="Center"
                            margin="10px"
                            variant="h6">
                                You have no characters yet.
                            </Typography>
                            </Box>}
                        </Box>}
                    <Divider/>
                    <Stack orientation="column">
                        <Button sx={{
                            marginBottom:'10px',
                            marginTop:'10px'
                        }} onClick={()=> {
                            navigate('/createcharacter');
                        }}>CREATE CHARACTER</Button>
                        <Button sx={{
                            marginBottom:'10px',
                        }} onClick={()=> {
                            navigate('/manageaccount');
                        }}>MANAGE ACCOUNT</Button>
                    </Stack>
            </Paper>
        </Box>
    </Container>
  )
}