import "../Styles/Login.css"
import AuthContext from '../Context/AuthContext';
import { useRef, useState, useEffect, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import useAccount from '../Hooks/useAccount';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import useCache from '../Hooks/useCache';



import axios from '../Api/axios';
import FormData from 'form-data';

const AUTH_ENDPOINT = "auth/authenticate"

export const Login = () => {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const accountData = useAccount();

    const from = location.state?.from?.pathname || "/";
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const cache = useCache();
    const userRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');

    const setErrMsg = (msg) => {
      setMessage(msg);
      setMessageType("error");
    }
    
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    useEffect(() => {
        userRef.current.focus();
        setMessageType(location.state?.messageType);
        setMessage(location.state?.message);
    }, [])

    useEffect(() => {
        if (user?.length > 0 || pwd?.length > 0)
          setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
      e.preventDefault();
      setErrMsg('');
      try {
          var form = new FormData()
          form.append('AccountName', user)
          form.append('Password', pwd)
          form.append('DeviceIdentifier', 'react-app')
          form.append('ClientVersion', 'debug')
          setLoading(true);
          const response = await axios.put(AUTH_ENDPOINT,
            form,
            {
              withCredentials: true
            });
          const AccessToken = response?.data?.accessToken;
          const AccessExpiration = response?.data?.accessExpiration;
          const RefreshExpiration = response?.data?.refreshExpiration;
          const DisplayName = response?.data?.displayName;
          const Access = response?.data?.access;
          const Email = response?.data?.email;
          const Confirmed = response?.data?.confirmed;
          setAuth({
            AccessToken: AccessToken,
            AccessExpiration: AccessExpiration,
            RefreshExpiration: RefreshExpiration
          });
          setLoading(false);
          accountData.saveLogin(DisplayName, Access, Email, Confirmed);
          cache.clear("dbw-cache-vote");
          cache.clear("dbw-cache-charlist");
          cache.clear("dbw-cache-friends");
          console.log(`welcome, ${DisplayName} :)`)
          setUser('');
          setPwd('');
          if (from && from?.length > 1) {
            navigate(from, {replace:true});
          }
          else{
            navigate('/account', {replace:true});
          }

      } catch (err) {
          setLoading(false);
          if (accountData.getInfo().logged){
            accountData.saveLogout();
          }
          if (!err?.response) {
              setErrMsg('Server is not responding. Try again later.');
          } else if (err.response?.status === 429) {
              setErrMsg('Woah! Slow down. Try again in a few minutes.');
          } else {
            const errorCode = err?.response?.data?.code
            switch(errorCode){
              case 1: // OUTDATED CLIENT VERSION
                setErrMsg('Login failed. This client is invalid or outdated.');
                break;
              case 2: // NAME OR PASSWORD EMPTY
                setErrMsg("Name and password can't be empty.");
                break;
              case 3: // ACCOUNT DOESN'T EXIST
                setErrMsg("Account not found.");
                break;
              case 4: // WRONG PASSWORD
                setErrMsg("Wrong password.");
                break;
              case 5: // FAILED TO SUBMIT LOGIN
                setErrMsg("Failed to submit login. Try again in a few seconds.");
                break;
              default:
                setErrMsg('Unknown error has occured. Try again later.');
                break;
            }
          }
      }
  }

  return (
    <Container component="main" maxWidth="xs">
        <Box 
        component="form"
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center'
        }}>
            <Paper elevation={16}
                sx={{
                    borderRadius: 10,
                }}>
                <Typography
                    variant="h4"
                    margin="15px"
                    color="white" 
                    textAlign="center">Login</Typography>
                    <Stack orientation="vertical" paddingX="20px">
                        <TextField variant="outlined"
                            required
                            id="userField"
                            label="Account Name"
                            autoComplete="off"
                            color="primary"
                            value={user}
                            ref={userRef}
                            onInput={e=>setUser(e.target.value)} 
                            sx={{
                                input:{color:'white', textAlign:'left'}
                            }}/>
                        <TextField 
                            required
                            variant="outlined"
                            id="passwordField"
                            autoComplete="off"
                            label="Password"
                            color="primary"
                            value={pwd}
                            onInput={e=>setPwd(e.target.value)} 
                            sx={{
                                marginTop: '15px',
                                marginBottom: '15px',
                                input:{color:'white', textAlign:'left'}
                            }}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff sx={{color:'white'}}/> : <Visibility sx={{color:'white'}}/>}
                                </IconButton>
                              </InputAdornment>
                            }}/>
                    </Stack>
                    <Stack alignItems="center">
                      <Button variant="contained" type="submit" sx={{minWidth:'200px', marginBottom:'10px'}}>LOG IN</Button>
                      <Button sx={{minWidth:'200px', marginBottom:'15px'}} onClick={()=> {
                        navigate('/createaccount')
                      }}>CREATE ACCOUNT</Button>
                      {loading ? <CircularProgress sx={{width:'32px', marginBottom:'10px'}}/> : null}
                      {message?.length > 0 ?
                      <Alert variant="filled" sx={{marginX:'15px', marginBottom:'15px', marginTop:'5px'}} severity={messageType === 'error' ? 'error' : 'success'}>
                        {message}
                      </Alert>
                       : null}
                    </Stack>
            </Paper>
        </Box>
    </Container>
  )
}
