import React from 'react';
import { Start } from "./Pages/Start";
import { Landing } from "./Pages/Landing";
import { Home } from "./Pages/Home";
import { Login } from "./Pages/Login";
import {useState, useEffect} from 'react';
import { ManageAccount } from "./Pages/ManageAccount";
import { CreateAccount } from "./Pages/CreateAccount";
import { Account } from "./Pages/Account";
import { CreateCharacter } from "./Pages/CreateCharacter";
import Vote from "./Pages/Vote";
import Missing from './Components/Missing';
import Layout from './Components/Layout';
import "./Styles/App.css";
import { Routes, Route } from 'react-router-dom';
import RequireAuth from './Components/RequireAuth';
import CustomNavbar from './Components/Navbar';
import Highscores from './Pages/Highscores';
import Team from './Pages/Team';
import OnlinePlayers from './Pages/OnlinePlayers';
import CharacterView from './Pages/CharacterView';
import {themeOptions} from './Styles/Theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material/';
import Stack from '@mui/material/Stack';
import useProgressiveImg from "./Hooks/useProgressiveImg";
import BackgroundLq from "./Icons/sky-lq.png";
import Background from "./Icons/sky.png";
import Typography from '@mui/material/Typography';

const theme = createTheme(themeOptions());

const APP_VERSION_DISPLAY = "";

function App() {
  const [src, { blur }] = useProgressiveImg(BackgroundLq, Background);
  
  return (
    <>
    <img id="bg" style={{opacity:'0.85', filter: blur ? "blur(20px)" : "none", transition: blur ? "none" : "filter 0.5s ease-out"}} src={src}/>
    <Typography sx={{position:'fixed', zIndex:-1, bottom:'0', right:'0', left:'0', fontFamily:'Dosis', fontSize:'10px', color:'darkGray'}} textAlign="center">{APP_VERSION_DISPLAY}<br/>Copyright © 2023 Dragon Ball World. All rights reserved.</Typography>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <CustomNavbar/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route path="landing" element={<Landing/>} />
          <Route path="home" element={<Home/>} />
          <Route path="login" element={<Login/>} />
          <Route path="createaccount" element={<CreateAccount/>} />
          <Route path="highscores" element={<Highscores/>}/>
          <Route path="search" element={<CharacterView/>}/>
          <Route path="online" element={<OnlinePlayers/>}/>
          <Route path="team" element={<Team/>}/>

          <Route element={<RequireAuth access='0'/>}>
            <Route path="account" element={<Account/>} />
            <Route path="createcharacter" element={<CreateCharacter/>} />
            <Route path="manageaccount" element={<ManageAccount/>}/>
            <Route path="vote" element={<Vote/>}/>
          </Route>

          <Route path="/" element={<Start/>} />
          <Route path="*" element={<Missing/>} />
        </Route>
      </Routes>
    </ThemeProvider>
    </>
  );
}

export default App;
