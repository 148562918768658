import React from 'react'
import {useState, useEffect, useRef} from 'react';
import logo_icon from '../Icons/logo.png'
import discord_icon from '../Icons/discord.png'
import "../Styles/Start.css"
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import axios from '../Api/axios';
import useCache from '../Hooks/useCache';
import { ConvertDateToString } from './CharacterView';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import ArticleIcon from '@mui/icons-material/Article';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import InfoIcon from '@mui/icons-material/Info';

const NEWS_ENDPOINT = "home/news/multi";
const MAZES_ENDPOINT = "game/mazes"

export function LightenDarkenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}

export const getCountdownString = (date) => {

    let countDown = date - new Date().getTime();
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    
    if (days > 0)
        return days+"d "+hours+"h "+minutes+"m "+seconds+"s";
    else if(hours > 0)
        return hours+"h "+minutes+"m "+seconds+"s";
    else if(minutes > 0)
        return minutes+" minute"+(minutes!==1?"s":"")+" and "+seconds+" second"+(seconds!==1?"s":"");
    else
        return seconds+" second"+(seconds!==1?"s":"");
  };

export const Home = () => {
    const navigate = useNavigate();
    const fetchedNews = useRef(false);
    const fetchedMazes = useRef(false);
    const cache = useCache();

    const [news, setNews] = useState([]);
    const [mazes, setMazes] = useState([]);
    const [mazeCounter, setMazeCounter] = useState([]);

    useEffect(()=> {
        const interval = setInterval(() => {
            setMazes(prevState=> {
                const list = prevState.map(maze =>{
                    maze.countdownString = getCountdownString(maze?.timerGoal?.time)
                    return maze;
                });
                return list;
            });
        }, 1000);
        const mazeFetcher = setInterval(() => {
            fetchedMazes.context = false;
            fetchMazes();
        }, 60000);
    }, [])

    const fetchMazes = () => {
        const fetch_data = async () => {
            fetchedMazes.context = true;
            try {
                if (cache.valid("dbw-cache-mazes")){
                    const data = cache.get("dbw-cache-mazes");
                    setMazes(data);
                } else {
                    const response = await axios.get(MAZES_ENDPOINT);
                    let pData = response?.data;
                    if (pData?.length > 0)
                    {
                        pData?.forEach((it, i) => {
                            pData[i].start = new Date(it?.start + 'Z');
                            pData[i].end = new Date(it?.end + 'Z');
                            if (it?.timerGoal){
                                pData[i].timerGoal.time = new Date(it?.timerGoal?.time).getTime();
                            }
                        })
                    }
                    setMazes(pData);
                    cache.fetch("dbw-cache-mazes", pData, 180*1000)
                }
            }
            catch(err)
            {
                if (!err?.response) {
                    if (cache.valid("dbw-cache-mazes")){
                        setMazes(cache.get("dbw-cache-mazes"));
                    } else{
                        
                    }
                } else if (err.response?.status === 429) {
                    if (cache.valid("dbw-cache-mazes")){
                        setMazes(cache.get("dbw-cache-mazes"));
                    } else{
                        
                    }
                }
            };
        }
        if (!fetchedMazes.context)
            fetch_data();
    }

    const fetchNews = () => {
        const fetch_data = async () => {
            fetchedNews.context = true;
            try {
                if (cache.valid("dbw-cache-news")){
                    const data = cache.get("dbw-cache-news");
                    setNews(data);
                } else {
                    const response = await axios.get(NEWS_ENDPOINT + "?index=0&count=5");
                    let pData = response?.data;
                    if (pData?.length > 0)
                    {
                        pData?.forEach((it, i) => {
                            pData[i].created = ConvertDateToString(it?.created + 'Z');
                            pData[i].edited = ConvertDateToString(it?.edited + 'Z');
                            pData[i].tags = it?.tags.split(",");
                        })
                    }
                    setNews(pData);
                    cache.fetch("dbw-cache-news", pData, 180*1000)
                }
            }
            catch(err)
            {
                if (!err?.response) {
                    if (cache.valid("dbw-cache-news")){
                        setNews(cache.get("dbw-cache-news"));
                    } else{
                        
                    }
                } else if (err.response?.status === 429) {
                    if (cache.valid("dbw-cache-news")){
                        setNews(cache.get("dbw-cache-news"));
                    } else{
                        
                    }
                }
            };
        }
        if (!fetchedNews.context)
            fetch_data();
    }

    useEffect(() => {
        fetchNews();
        fetchMazes();
    });

  return (
    <Container component="main" maxWidth="xl" sx={{paddingTop:`calc(120px - 7vh)`}}>
        <Grid container orientation="row" justifyContent="center" alignItems="center">
            <Grid item xs="auto" md="auto" lg="auto" xl="auto">
                <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                margin: '30px'
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                            minWidth: '350px',
                            width: '50vw',
                            maxWidth: '800px'
                        }}>
                        <Typography
                            variant="h4"
                            margin="15px"
                            color="white" 
                            textAlign="center">News</Typography>
                        <Divider/>
                        {news?.length === 0 ? 
                        <Typography
                            variant="h6"
                            margin="15px"
                            color="lightgray" 
                            textAlign="center">There are no posts yet.</Typography>
                        : <Stack orientation="column" spacing={2} sx={{padding:'15px'}}>
                            {news.map((post) => 
                            <Paper key={post?.id} elevation={10} sx={{borderRadius: 5, backgroundColor: '#1c1c4c'}}>
                                <Stack orientation="column" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" sx={{marginX:'10px'}}>
                                        <Grid item xs="auto">
                                            <ArticleIcon/>
                                        </Grid>
                                        <Grid item xs="8">
                                            <Typography variant="h6" color="white" margin="15px">{post?.title}</Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Grid container orientation="row" justifyContent="flex-start" alignItems="center">
                                                {post.tags.map((tag, i)=>
                                                <Grid key={i} item xs="auto"> 
                                                    <Chip label={tag} sx={{color:'darkgray'}} />
                                                </Grid>)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h7" color="white" margin="10px">{post?.content}</Typography>
                                    <Typography variant="h7" textAlign="right" color="darkgray" sx={{fontStyle:'italic', marginLeft:'10px'}} margin="5px">posted {post?.created} by {post?.authorName}</Typography>
                                </Stack>
                            </Paper>)}
                        </Stack>}
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto" xl="auto">
                <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                margin: '30px'
                }}>
                    <Paper elevation={16}
                        sx={{
                            borderRadius: 10,
                            minWidth: '300px',
                            width: '20vw',
                            maxWidth: '400px'
                        }}>
                        <Typography
                            variant="h4"
                            margin="15px"
                            color="white" 
                            textAlign="center">Mazes</Typography>
                        <Divider/>
                        {mazes?.length === 0 ? 
                        <Typography
                            variant="h6"
                            margin="15px"
                            color="lightgray" 
                            textAlign="center">No mazes found.</Typography>
                        : <Stack orientation="column" spacing={2}>
                            {mazes.map((maze, i) =>
                            <div key={i} style={{position:'relative', margin:'10px'}}>
                            <Paper elevation={20} sx={{
                                borderRadius:'10px', 
                                minHeight:'100px', 
                                height:'15vh', 
                                maxHeight:'150px', 
                                backgroundColor:LightenDarkenColor(maze?.colorTheme, -95)
                                }}/>
                            <Box component="img" 
                                src={maze?.backgroundImage}
                                sx={{
                                    position: 'absolute',
                                    top:'0px',
                                    left:'0px',
                                    right:'0px',
                                    bottom:'0px',
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: '10px',
                                    border:1,
                                    borderColor: maze?.colorTheme,
                                    opacity:'0.7'
                                    }}/>
                            <Box sx={{
                                position:'absolute',
                                top:'0px',
                                left:'0px',
                                right:'0px',
                                bottom:'0px',
                                width: "100%",
                                height: "100%"
                                }}>
                                <Typography variant="h7" sx={{
                                    fontSize:'20px', 
                                    fontWeight:'bold', 
                                    color:LightenDarkenColor(maze?.colorTheme, 95)}}>
                                        {maze?.name}
                                </Typography>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs="auto">
                                        <StarBorderIcon sx={{
                                            color:LightenDarkenColor(maze?.colorTheme, 60)
                                        }}/>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography variant="h7" sx={{
                                            fontSize:'16px', 
                                            color:LightenDarkenColor(maze?.colorTheme, 60)}}>
                                                {maze?.minLevel} - {maze?.maxLevel} lv
                                    </Typography>
                                    </Grid>
                                </Grid>
                                {maze?.countdownString?.includes('-') ?
                                <Typography variant="h7" sx={{
                                    fontSize:'16px', 
                                    position:'absolute',
                                    bottom:'0px',
                                    left:'0px',
                                    right:'0px',
                                    textAlign:'center',
                                    color:LightenDarkenColor(maze?.colorTheme, 60)}}>
                                        {maze?.timerGoal?.goal} very soon..
                                </Typography>
                                :
                                <Typography variant="h7" sx={{
                                    fontSize:'16px', 
                                    position:'absolute',
                                    bottom:'0px',
                                    left:'0px',
                                    right:'0px',
                                    textAlign:'center',
                                    color:LightenDarkenColor(maze?.colorTheme, 60)}}>
                                        {maze?.timerGoal?.goal} in {maze?.countdownString}
                                </Typography>}
                            </Box>
                            </div>)}
                        </Stack>}
                    </Paper>
                </Box>
            </Grid>
        </Grid>
        
    </Container>
  )
}
