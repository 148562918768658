import React, {useEffect, useState, useRef, Fragment} from 'react';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Styles/Account.css';
import useCache from '../Hooks/useCache';
import shard_icon from '../Icons/shard.png';
import premium_icon from '../Icons/premium.png';
import reflink_icon from '../Icons/reflink.png';
import useLogout from '../Hooks/useLogout';
import CircularProgress from '@mui/material/CircularProgress';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import { Share } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { ConvertDateToString } from './CharacterView';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import FormData from 'form-data';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import useAccount from '../Hooks/useAccount';
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';

import QRCode from "react-qr-code";

const ONLOAD_ENDPOINT = "auth/account";
const REF_ENDPOINT = "game/account/refcode";
const SHOP_ENDPOINT = "game/account/shop";
const TWOFACTOR_ENDPOINT = "auth/account/twofactor"

const SHARD_AMOUNTS = [
    {
        id: 1,
        amount: 10,
        price: 20,
        discountPercentage: 0
    },
    {
        id: 2,
        amount: 20,
        price: 38,
        discountPercentage: 5
    },
    {
        id: 3,
        amount: 30,
        price: 55,
        discountPercentage: 8.3
    },
    {
        id: 4,
        amount: 40,
        price: 71,
        discountPercentage: 11.2
    },
    {
        id: 5,
        amount: 50,
        price: 86,
        discountPercentage: 14
    },
    {
        id: 6,
        amount: 60,
        price: 100,
        discountPercentage: 16.7
    }
]

const steps = ['Select amount', 'Review and confirm info', 'Payment details'];

const twoFactorSteps = ['Install App', 'Scan QR Code', 'Verify Code'];

export const ManageAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const accountInfo = useAccount();
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState([]); // load data
    const [refData, setRefData] = useState([]); // ref data
    const [shopData, setShopData] = useState([]); // shop data
    const loadFetched = useRef(false);
    const [refButtonLoading, setRefButtonLoading] = useState(false);
    const [refButtonError, setRefButtonError] = useState("");
    const [refError, setRefError] = useState("");
    const [refButton, setRefButton] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoOpen, setInfoOpen] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const cache = useCache();
    const [openRefDialog, setOpenRefDialog] = useState(false);
    const [openShopDialog, setOpenShopDialog] = useState(false);
    const [openShardDialog, setOpenShardDialog] = useState(false);
    const [openTwoFactorEnableDialog, setOpenTwoFactorEnableDialog] = useState(false);
    const logout = useLogout();
    const [twoFactorChecked, setTwoFactorChecked] = useState(false);

    const [previewShopOffer, setPreviewShopOffer] = useState({});
    const [openShopPreviewDialog, setOpenShopPreviewDialog] = useState(false);
    const [pwd, setPwd] = useState("");

    const codeRef = useRef();
    const [code, setCode] = useState("");
    const [qrKey, setQrKey] = useState("");

    const [activeStep, setActiveStep] = useState(0);
    const [amount, setAmount] = useState(0);
    const [confirmed, setConfirmed] = useState(false);

    const [activeStepTwoFactor, setActiveStepTwoFactor] = useState(0);

    const [paymentData, setPaymentData] = useState({});

    const [shareAnchor, setShareAnchor] = useState(null);
    const shareOpen = Boolean(shareAnchor);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const sendChangePasswordRequest = async (email) => {
        try {
            var form = new FormData();
            var redirectUrl = window.location.protocol + "//" + window.location.host + "/landing?action=changepassword";
            form.append('RedirectUrl', redirectUrl);
            const response = await axiosPrivate.put("auth/account/password/request", form);
            let pData = response?.data;
            if (pData?.code === -1){
                setSuccessMessage("Email with instructions has been sent on "+email+".");
                setSuccessOpen(true);
            }
        }
        catch (err) {
            if (err?.response?.data?.code){
                setErrorMessage(err?.response?.data?.message);
                setErrorOpen(true);
            }
        }
    }

    const sendEmailConfirmation = async (email) => {
        try {
            var form = new FormData();
            var redirectUrl = window.location.protocol + "//" + window.location.host + "/landing?action=email";
            form.append('RedirectUrl', redirectUrl);
            const response = await axiosPrivate.put("auth/account/email/verify", form);
            let pData = response?.data;
            if (pData?.code === -1){
                setSuccessMessage("Confirmation email has been sent on "+email+".");
                setSuccessOpen(true);
                navigate('/manageaccount', {replace:true})
            }
        }
        catch (err) {
            if (err?.response?.data?.code){
                setErrorMessage(err?.response?.data?.message);
                setErrorOpen(true);
            }
        }
    }

    useEffect(()=> {
        setSuccessOpen(location?.state?.messageType === "success");
        setSuccessMessage(location?.state?.message);
    }, [])

    useEffect(() => {
        if (location.state?.confirmEmail && location.state?.email?.length > 0){
            sendEmailConfirmation(location.state?.email);
            location.state.confirmEmail = false;
        }
    });

    const handleDonationRequest = async () => {
        try {
            setInfoMessage("Requesting payment details..");
            setInfoOpen(true);
            var shards = SHARD_AMOUNTS[amount-1]?.amount;
            const response = await axiosPrivate.put("game/account/donation?shards="+shards);
            setPaymentData(response?.data);
            setActiveStep((prevActiveStep)=> prevActiveStep + 1);
        }
        catch (err) {
            if (err?.response?.data?.code){
                setErrorMessage(err?.response?.data?.message);
                setErrorOpen(true);
            } else {
                setErrorMessage("Failed to fetch payment data. Try again later.");
                setErrorOpen(true);
            }
        }
    }

    const handleShopOffer = (previewItem) => {
        setPreviewShopOffer(previewItem);
        setPwd("");
        setOpenShopPreviewDialog(true);
    }

    const handleShopPurchase = async () => {
        try {
            var form = new FormData();
            form.append('Password', pwd?.length > 0 ? pwd : 'empty_field');
            const response = await axiosPrivate.put("game/account/shop?offerid="+previewShopOffer?.id,
            form, {withCredentials:true});
            let pData = response?.data;
            if (pData?.code === -1){
                loadFetched.context = false;
                fetchAccountInfo(true);
                setOpenShopPreviewDialog(false);
                setSuccessMessage("Purchase successful!");
                setSuccessOpen(true);
            }
        }
        catch (err) {
            if (err?.response?.data?.code){
                setErrorMessage(err?.response?.data?.message);
                setErrorOpen(true);
            }
        }
    }

    const handleCreateCode = async () => {
        try {
            setRefButtonLoading(true);
            const response = await axiosPrivate.post("game/account/refcode");
            let pData = response?.data;
            if (pData?.code === -1){
                fetchRefCode();
            }
        }
        catch (err) {
            if (err?.response?.data?.code){
                setRefButtonError(err?.response?.data?.message);
            }
        }
        setRefButtonLoading(false);
    }

    const handleOpenRef = () => {
        fetchRefCode();
        setOpenRefDialog(true);
    }

    const handleOpenShop = () => {
        fetchShopOffers();
    }

    const fetchShopOffers = () => {
        const fetch_data = async () => {
            try {
                if (cache.valid("dbw-cache-shop")){
                    const data = cache.get("dbw-cache-shop");
                    setShopData(data);
                } else {
                    const response = await axiosPrivate.get(SHOP_ENDPOINT);
                    let pData = response?.data;
                    setShopData(pData);
                    setErrorOpen(false);
                    cache.fetch("dbw-cache-shop", pData, 180*1000);
                }
                setOpenShopDialog(true);
            }
            catch(err)
            {
                if (!err?.response) {
                    if (cache.valid("dbw-cache-shop")){
                        setShopData(cache.get("dbw-cache-shop"));
                        setErrorOpen(false);
                        setOpenShopDialog(true);
                    } else{
                        setErrorMessage("Failed to load shop offers. Try again later.");
                        setErrorOpen(true);
                    }
                } else if (err.response?.status === 429) {
                    if (cache.valid("dbw-cache-shop")){
                        setShopData(cache.get("dbw-cache-shop"));
                        setErrorOpen(false);
                        setOpenShopDialog(true);
                    } else{
                        setErrorMessage("Failed to load shop offers. Try again later.");
                        setErrorOpen(true);
                    }
                } else if (err.response?.status === 401) {
                    navigate('/login', {replace:true});
                } else if (err.response?.status === 400) { // bad request
                    setErrorMessage(err.response?.data?.message);
                    setErrorOpen(true);
                    setOpenShopDialog(true);
                } else {
                    setErrorMessage("Failed to load shop offers. Try again later.");
                    setErrorOpen(true);
                }
            };
        }
        fetch_data();
    }

    const fetchRefCode = () => {
        const fetch_data = async () => {
            try {
                if (cache.valid("dbw-cache-refcode")){
                    const data = cache.get("dbw-cache-refcode");
                    setRefData(data);
                } else {
                    const response = await axiosPrivate.get(REF_ENDPOINT);
                    let pData = response?.data;
                    pData.created = ConvertDateToString(pData?.created);
                    setRefData(pData);
                    setRefError("");
                    cache.fetch("dbw-cache-refcode", pData, 45*1000);
                }
            }
            catch(err)
            {
                if (!err?.response) {
                    if (cache.valid("dbw-cache-refcode")){
                        setRefData(cache.get("dbw-cache-refcode"));
                        setRefError("");
                    } else{
                        setRefError("Failed to load refcode info. Try again later.");
                    }
                } else if (err.response?.status === 429) {
                    if (cache.valid("dbw-cache-refcode")){
                        setRefData(cache.get("dbw-cache-refcode"));
                        setRefError("");
                    } else{
                        setRefError("Failed to load refcode info. Try again later.");
                    }
                } else if (err.response?.status === 401) {
                    navigate('/login', {replace:true});
                } else if (err.response?.status === 400) { // bad request
                    setRefError(err.response?.data?.message);
                    if (err.response?.data?.code === 2) { // acc doesnt have refcode
                        setRefButton(true);
                        setRefError("Your account doesn't have a refcode yet.");
                    }
                } else {
                    setRefError("Failed to load refcode info. Try again later.");
                }
            };
        }
        
        fetch_data();
    }

    const fetchAccountInfo = (force) => {
        const fetch_data = async (force) => {
            loadFetched.context = true;
            try {
                if (cache.valid("dbw-cache-account" && !force)){
                    const data = cache.get("dbw-cache-account");
                    setTwoFactorChecked(data?.twoFactorEnabled);
                    setData(data);
                } else {
                    const response = await axiosPrivate.get(ONLOAD_ENDPOINT);
                    let pData = response?.data;
                    pData.created = ConvertDateToString(pData?.created);
                    setData(pData);
                    setTwoFactorChecked(pData?.twoFactorEnabled);
                    accountInfo.updateConfirmed(accountInfo.getInfo(), pData?.confirmed);
                    cache.fetch("dbw-cache-account", pData, 45*1000)
                }
                fetchRefCode();
            }
            catch(err)
            {
                console.log(err);
                if (!err?.response) {
                    if (cache.valid("dbw-cache-account")){
                        setData(cache.get("dbw-cache-account"));
                    } else{
                        navigate('/login', {replace:true});
                    }
                } else if (err.response?.status === 429) {
                    if (cache.valid("dbw-cache-account")){
                        setData(cache.get("dbw-cache-account"));
                    } else{
                        navigate('/login', {replace:true});
                    }
                } else if (err.response?.status === 401) {
                    navigate('/login', {replace:true});
                } else {
                    navigate('/login', {replace:true});
                }
            };
        }
        if (!loadFetched.context)
            fetch_data(force);
    }
    // fetch account info
    useEffect(() => {
        fetchAccountInfo(false);
    });

    const onTwoFactorChange = async () => {
        if (!twoFactorChecked){
            if (qrKey?.length !== 0) {
                setOpenTwoFactorEnableDialog(true);
                return;
            }
            const fetchResult = await fetchQRKey();
            if (!fetchResult){
                return;
            }
            setOpenTwoFactorEnableDialog(true);
            return;
        }
        setTwoFactorChecked((prev) => !prev);
    }

    const onTwoFactorVerify = async () => {
        if (code?.length === 0){
            setErrorMessage("Code cannot be empty.");
            setErrorOpen(true);
            return;
        }
        const setupResult = await setupTwoFactor();
        setCode("");
        setQrKey("");
        setActiveStepTwoFactor(0);
        setOpenTwoFactorEnableDialog(false);
        if (setupResult){
            setSuccessMessage("Two-factor authentication has been enabled.");
            setSuccessOpen(true);
            fetchAccountInfo(true);
            setTwoFactorChecked(true);
        }
    }

    const fetchQRKey = async () => {
        const fetch_data = async () => {
            try {
                const response = await axiosPrivate.get(TWOFACTOR_ENDPOINT);
                let responseData = response?.data;
                let secret = responseData?.twoFactorSecret;
                let issuer = responseData?.issuer;
                let displayName = responseData?.displayName;
                let qrLink = "otpauth://totp/"+issuer+":"+displayName+"%20-%20Game%20Account?secret="+secret
                let qrLinkFormatted = qrLink.replace(/ /g, "%20");
                setQrKey(qrLinkFormatted);
                return true;
            }
            catch(err)
            {
                if (!err?.response) {
                    setErrorMessage("Failed to request two-factor info. Please try again later.");
                    setErrorOpen(true);
                } else if (err.response?.status === 429) {
                    setErrorMessage("You are being rate-limited. Please try again later.");
                    setErrorOpen(true);
                } else if (err.response?.status === 401) {
                    navigate('/login', {replace:true});
                } else if (err.response?.status === 400) { // bad request
                    setErrorMessage(err.response?.data?.message);
                    setErrorOpen(true);
                } else {
                    setErrorMessage("Failed to request two-factor info. Please try again later.");
                    setErrorOpen(true);
                }
                return false;
            };
        }
        
        return await fetch_data();
    }

    const setupTwoFactor = async () => {
        try {
            var form = new FormData();
            form.append('Code', code);
            const response = await axiosPrivate.patch(TWOFACTOR_ENDPOINT, form);
            return true;
        }
        catch(err)
        {
            if (!err?.response) {
                setErrorMessage("Failed to setup two-factor authentication. Please try again later.");
                setErrorOpen(true);
            } else if (err.response?.status === 429) {
                setErrorMessage("You are being rate-limited. Please try again later.");
                setErrorOpen(true);
            } else if (err.response?.status === 401) {
                navigate('/login', {replace:true});
            } else if (err.response?.status === 400) { // bad request
                setErrorMessage(err.response?.data?.message);
                setErrorOpen(true);
            } else {
                setErrorMessage("Failed to setup two-factor authentication. Please try again later.");
                setErrorOpen(true);
            }
            return false;
        };
    }

  return (
    <Container component="main" maxWidth="xs">
        
        <Snackbar open={errorOpen} autoHideDuration={5000} onClose={(e, r) => {
            if (r === "clickaway") {
                return;
            }
            setErrorOpen(false);
        }}>
            <Alert onClose={()=>{setErrorOpen(false);}} variant="filled" severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={successOpen} autoHideDuration={10000} onClose={(e, r) => {
            if (r === "clickaway") {
                return;
            }
            setSuccessOpen(false);
        }}>
            <Alert onClose={()=>{setSuccessOpen(false);}} variant="filled" severity="success" sx={{ width: '100%' }}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={infoOpen} autoHideDuration={2000} onClose={(e, r) => {
            setInfoOpen(false);
        }}>
            <Alert onClose={()=>{setInfoOpen(false);}} variant="filled" severity="info" sx={{ width: '100%' }}>
                {infoMessage}
            </Alert>
        </Snackbar>
        <Box 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center'
        }}>
            <Paper elevation={16}
                sx={{
                    borderRadius: 10,
                }}>
                <Typography
                    variant="h4"
                    margin="15px"
                    color="white" 
                    textAlign="center">Manage Account</Typography>
                    <Divider sx={{marginTop:'5px', marginBottom:'10px'}}/>
                    <Stack orientation="column">
                        <Grid container 
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                            <Grid item xs="auto">
                                <Typography variant="h7" color="lightgray" sx={{fontSize:'20px', marginLeft:'20px'}}>Display Name:</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                {data?.displayName ? 
                                <Typography variant="h7" color="white" sx={{fontSize:'20px', marginRight:'20px'}}>{data?.displayName}</Typography>
                                : <Skeleton variant="text" sx={{ width:'100px', fontSize: '1rem', marginRight:'20px' }} />}
                            </Grid>
                        </Grid>
                        <Grid container 
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                            <Grid item xs="auto">
                                <Typography variant="h7" color="lightgray" sx={{fontSize:'20px', marginLeft:'20px'}}>Created:</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                {data?.created ?
                                <Typography variant="h7" color="white" sx={{fontSize:'20px', marginRight:'20px'}}>{data?.created}</Typography>
                                :<Skeleton variant="text" sx={{width:'125px', fontSize: '1rem', marginRight:'20px' }} />}
                            </Grid>
                        </Grid>
                        <Grid container 
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                            <Grid item xs="auto">
                                <Typography variant="h7" color="lightgray" sx={{fontSize:'20px', marginLeft:'20px'}}>Email:</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                { data?.email ?
                                <Typography variant="h7" color="white" sx={{fontSize:'20px', marginRight:'20px'}}>{data?.email}</Typography>
                                : <Skeleton variant="text" sx={{width:'150px', fontSize: '1rem', marginRight:'20px' }} />}
                            </Grid>
                        </Grid>
                        <Grid container 
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                            <Grid item xs="auto">
                                <Typography variant="h7" color="lightgray" sx={{fontSize:'20px', marginLeft:'20px'}}>Email Verified:</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                { data?.email ?
                                <Typography variant="h7" color="white" sx={{fontSize:'20px', marginRight:'20px'}}>{data?.confirmed ? "Yes" : "No"}</Typography>
                                : <Skeleton variant="text" sx={{width:'30px', fontSize: '1rem', marginRight:'20px' }} />}
                            </Grid>
                        </Grid>
                        <Grid container 
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                            <Grid item xs="auto">
                                <Typography variant="h7" color="lightgray" sx={{fontSize:'20px', marginLeft:'20px'}}>Two-Factor Authentication:</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                { data?.email ?
                                <Switch color="secondary" checked={twoFactorChecked} onChange={onTwoFactorChange} />
                                : <Skeleton variant="text" sx={{width:'30px', fontSize: '1rem', marginRight:'20px' }} />}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider sx={{marginTop:'10px', marginBottom:'5px'}}/>
                    <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
                        <Grid item xs="auto">
                            <Stack orientation="column" alignItems="center">
                                <Box
                                    component="img"
                                    sx={{
                                    width: '32px'
                                    }}
                                    alt="Image"
                                    src={shard_icon}
                                />
                                {data?.displayName ?
                                <Typography variant="h7" color="white" sx={{fontSize:'16px'}}>{data?.shards ? data?.shards : '0'} shard{data?.shards !== 1 ? 's' : ''}</Typography>
                                : <Skeleton variant="text" sx={{width:'40px', fontSize:'1rem'}}/>}
                                <Link component="button" onClick={()=>{setOpenShardDialog(true);}}>Get Shards</Link>
                            </Stack>
                        </Grid>
                        <Grid item xs="auto">
                            <Stack orientation="column" alignItems="center">
                                <Box
                                    component="img"
                                    sx={{
                                    width: '32px'
                                    }}
                                    alt="Image"
                                    src={premium_icon}
                                />
                                {data?.displayName ?
                                <Typography variant="h7" color="white" sx={{fontSize:'16px'}}>{data?.premiumDays > 0 ? (data?.premiumDays + " day"+(data?.premiumDays !== 1 ? 's' : '')+" left") : "Free account"}</Typography>
                                : <Skeleton variant="text" sx={{width:'40px', fontSize:'1rem'}}/>}
                                <Link component="button" onClick={handleOpenShop}>Get Premium</Link>
                            </Stack>
                        </Grid>
                        <Grid item xs="auto">
                            <Stack orientation="column" alignItems="center">
                                <Box
                                    component="img"
                                    sx={{
                                    width: '32px'
                                    }}
                                    alt="Image"
                                    src={reflink_icon}
                                />
                                { data?.displayName ?
                                <Typography variant="h7" color="white" sx={{fontSize:'16px'}}>{refData?.useTimes ? (refData?.useTimes + " invite" + (refData?.useTimes !== 1 ? 's' : '')) : "0 invites"}</Typography>
                                : <Skeleton variant="text" sx={{width:'40px', fontSize:'1rem'}}/>}
                                <Link component="button" onClick={handleOpenRef}>View Code</Link>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Dialog open={openShopPreviewDialog} onClose={()=>{setOpenShopPreviewDialog(false);}}>
                        <DialogTitle>Confirm Purchase</DialogTitle>
                        <DialogContent>
                            <Stack orientation="column"
                            alignItems="center"
                            justifyContent="center">
                                <Box
                                    component="img"
                                    sx={{
                                    width: '64px'
                                    }}
                                    alt="Image"
                                    src={previewShopOffer?.image}/>
                                <Typography variant="h6" sx={{fontSize:'14px'}}>{previewShopOffer?.name}</Typography>
                                <Typography variant="h7" sx={{fontSize:'12px', color:'lightgray'}}>{previewShopOffer?.subType}</Typography> 
                                <Divider/>
                                <TextField 
                                    required
                                    variant="outlined"
                                    id="passwordField"
                                    autoComplete="off"
                                    label="Your Password"
                                    color="primary"
                                    value={pwd}
                                    onInput={e=>setPwd(e.target.value)} 
                                    sx={{
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                        input:{color:'white', textAlign:'left'}
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff sx={{color:'white'}}/> : <Visibility sx={{color:'white'}}/>}
                                        </IconButton>
                                    </InputAdornment>
                                    }}/>
                                    <Button variant="contained" onClick={()=>{handleShopPurchase();}} sx={{padding:'2px', textTransform:'none', marginTop:'5px'}}>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs="auto">
                                                <Typography variant="h7" sx={{fontSize:'13px', color:'lightgray', padding:'5px'}}>Purchase for</Typography>
                                            </Grid>
                                            <Grid item xs="auto">
                                            <Box
                                                component="img"
                                                sx={{
                                                    width: '24px'
                                                }}
                                                alt="Image"
                                                src={shard_icon}/>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Typography variant="h7" sx={{fontSize:'13px', color:'lightgray', paddingRight:'5px'}}>{previewShopOffer?.price}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Button>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setOpenShopPreviewDialog(false);}}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openRefDialog} onClose={()=>{setOpenRefDialog(false);}}>
                        <DialogTitle>Referral System</DialogTitle>
                        <DialogContent>
                            {refError?.length > 0 ?
                             <Stack orientation="column" alignItems="scretch" justifyContent="center">
                                <Typography variant="h7" sx={{marginBottom:'5px'}}>{refError}</Typography>
                                <Divider/>
                                {refButton ? (
                                    refButtonLoading ? <CircularProgress/> 
                                    : <Button variant="contained" onClick={handleCreateCode}>Create New Code</Button>
                                    ): null}
                                {refButtonError?.length > 0 ? <Alert sx={{marginTop:'5px'}} variant="filled" severity="error">
                                    {refButtonError}
                                </Alert> : null}
                            </Stack>
                             : <div>
                            <DialogContentText>
                                Your referral code has been used by {refData?.useTimes} {refData?.useTimes === 1 ? "person" : "people"}. You will earn bonus shards from their first donation. Visit our wiki to learn more about referral system.
                            </DialogContentText>
                            <Divider sx={{marginY: '5px'}}/>
                            <Typography variant="h4" textAlign="center">{refData?.code}</Typography>
                            <Grid container justifyContent="center">
                                <Grid item xs="auto">
                                    <IconButton
                                        size="small"
                                        aria-label="copy"
                                        aria-controls="copy-reflink"
                                        onClick={()=>{
                                            navigator.clipboard.writeText(refData?.code);
                                            setInfoMessage("Copied to clipboard.");
                                            setInfoOpen(true);
                                        }}
                                        color="secondary">
                                        <ContentCopy />
                                    </IconButton>
                                </Grid>
                                <Grid item xs="auto">
                                    <IconButton
                                        size="small"
                                        aria-label="share"
                                        aria-controls="share-reflink"
                                        onClick={(e)=>{setShareAnchor(e.currentTarget);}}
                                        color="secondary">
                                        <Share />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Menu
                                id="share-menu"
                                anchorEl={shareAnchor}
                                open={shareOpen}
                                onClose={()=>{setShareAnchor(null);}}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}>
                                <MenuItem onClick={()=>{
                                    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/createaccount?ref" + refData?.code);
                                    setInfoMessage("Copied to clipboard.");
                                    setInfoOpen(true);
                                }}>
                                    <ListItemIcon>
                                        <ContentCopy sx={{color:'white'}} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Copy account creation link
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/?ref=" + refData?.code);
                                    setInfoMessage("Copied to clipboard.");
                                    setInfoOpen(true);
                                }}>
                                    <ListItemIcon>
                                        <ContentCopy sx={{color:'white'}} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Copy home page link
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                            <Divider sx={{marginY: '5px'}}/>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item xs="auto">
                                    <IconButton
                                        size="small"
                                        aria-label="copy"
                                        aria-controls="copy-reflink"
                                        onClick={()=>{}}
                                        color="inherit">
                                        <CheckCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography variant="h7" sx={{fontSize:'15px'}}>You've earned {refData?.totalEarned} shards in total ({refData?.donationTimes} donations)</Typography>
                                </Grid>
                            </Grid> </div>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setOpenRefDialog(false);}}>Close</Button>
                            <Button onClick={()=>{window.open('https://dbwots.pl/');}}>Learn More</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openShopDialog} onClose={()=>{setOpenShopDialog(false);}}>
                        <DialogTitle>Shard Shop</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You may exchange your shards for premium status here. All of your characters on this account get the benefits of premium. Also, you may use "!shard" command in-game to materialize your shards and trade with other players.
                            </DialogContentText>
                            <Divider sx={{marginY: '10px'}}/>
                            <Paper elevation={12} sx={{padding:'10px', backgroundColor:'#1c1c4c', maxHeight: '38vh', minHeight: '250px', overflowY: 'scroll'}}>
                                <Grid container justifyContent="space-between" alignItems="center" spacing={1} direction="row">
                                    {shopData.map((offer)=>
                                    <Grid item xs="auto" key={offer?.id}>
                                        <Paper elevation={6} sx={{padding:'10px', width:'160px'}}>
                                            <Stack justifyContent="center" alignItems="center">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                    width: '64px'
                                                    }}
                                                    alt="Image"
                                                    src={offer?.image}/>
                                                <Typography variant="h6" sx={{fontSize:'14px'}}>{offer?.name}</Typography>
                                                <Typography variant="h7" sx={{fontSize:'12px', color:'lightgray'}}>{offer?.subType}</Typography>
                                                <Button variant="contained" disabled={data?.shards < offer?.price} onClick={()=>{handleShopOffer(offer);}} sx={{padding:'2px', textTransform:'none', marginTop:'5px'}}>
                                                    <Grid container justifyContent="center" alignItems="center">
                                                        <Grid item xs="auto">
                                                            <Typography variant="h7" sx={{fontSize:'13px', color:'lightgray', padding:'5px'}}>Purchase for</Typography>
                                                        </Grid>
                                                        <Grid item xs="auto">
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                width: '24px'
                                                            }}
                                                            alt="Image"
                                                            src={shard_icon}/>
                                                        </Grid>
                                                        <Grid item xs="auto">
                                                            <Typography variant="h7" sx={{fontSize:'13px', color:'lightgray', paddingRight:'5px'}}>{offer?.price}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Button>
                                            </Stack>
                                        </Paper>
                                    </Grid>
                                    )}
                                </Grid>
                            </Paper>
                            <Divider sx={{marginY: '10px'}}/>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs="auto">
                                    <Grid container orientation="row" justifyContent="center" spacing={1}>
                                        <Grid item xs="auto"> 
                                            <WarningIcon sx={{color:'yellow'}}/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography variant="h7" sx={{color:'yellow', fontSize:'15px'}}>
                                            All shop offers are non-refundable.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs="auto">
                                        <Box
                                            component="img"
                                            sx={{
                                                width: '32px'
                                            }}
                                            alt="Image"
                                            src={shard_icon}/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography variant="h7" sx={{fontSize:'15px', color:'lightgray', paddingRight:'5px'}}>{data?.shards ? data?.shards : '0'} shards</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setOpenShopDialog(false);}}>Close</Button>
                            <Button onClick={()=>{window.open('https://dbwots.pl/');}}>Learn More</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openShardDialog} onClose={()=>{setOpenShardDialog(false);}}>
                        <DialogTitle>Donate</DialogTitle>
                        <DialogContent>
                            <Stepper activeStep={activeStep} sx={{marginBottom:'10px'}}>
                                {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                            
                            <Divider sx={{marginY: '10px'}}/>
                            { activeStep === 0 ? (
                                <div>
                                <DialogContentText>
                                    You can receive shards from donating to the server. All donations are used for growing and maintaining the server. Choose which option are you interested in.
                                </DialogContentText>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={amount}
                                        onChange={(e)=>{setAmount(e.target.value)}}
                                        name="radio-buttons-group">
                                    {SHARD_AMOUNTS.map((amo) => 
                                        <FormControlLabel key={amo.id} value={amo.id} control={<Radio />} label={
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item xs="auto">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: '16px'
                                                    }}
                                                    alt="Image"
                                                    src={shard_icon}/>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Typography variant="h7" sx={{fontSize:'14px', color:'lightgray'}}>{amo.amount} for {amo.price}zł</Typography>
                                                </Grid>
                                                {amo.discountPercentage > 0 ?
                                                <Grid item xs="auto">
                                                    <Typography variant="h7" sx={{fontSize:'14px', color:'lightgreen', paddingLeft:'5px'}}>(-{amo.discountPercentage}%)</Typography>
                                                </Grid>
                                                : null
                                                }
                                            </Grid>
                                        } />
                                    )}
                                    </RadioGroup>
                                </div>
                            ) : (activeStep === 1 ? (
                                <div>
                                <DialogContentText>
                                    Please read the details below and confirm it. In the next step you'll be given the payment info and a unique code.
                                </DialogContentText>
                                <Divider sx={{marginY: '10px'}}/>
                                <Stack orientation="column" justifyContent="center" alignItems="center" spacing={2}>
                                    <Stack orientation="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h7" color="secondary" sx={{fontSize:'12px'}}>Account details</Typography>
                                        <Typography variant="h7" sx={{fontSize:'16px', color:'white'}}>{data?.name} ● {data?.email}</Typography>
                                    </Stack>
                                    <Stack orientation="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h7" color="secondary" sx={{fontSize:'12px'}}>Shard amount</Typography>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs="auto">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: '16px',
                                                        marginTop:'3px'
                                                    }}
                                                    alt="Image"
                                                    src={shard_icon}/>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Typography variant="h7" sx={{fontSize:'16px', color:'white'}}>{SHARD_AMOUNTS[amount-1]?.amount} shards</Typography>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack orientation="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h7" color="secondary" sx={{fontSize:'12px'}}>New balance</Typography>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs="auto">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: '16px',
                                                        marginTop:'3px'
                                                    }}
                                                    alt="Image"
                                                    src={shard_icon}/>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Typography variant="h7" sx={{fontSize:'16px', color:'white'}}>{data?.shards}</Typography>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <DoubleArrowIcon color="secondary" sx={{marginX:'5px', marginTop:'5px'}}/>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: '16px',
                                                        marginTop:'3px'
                                                    }}
                                                    alt="Image"
                                                    src={shard_icon}/>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Typography variant="h7" sx={{fontSize:'16px', color:'white'}}>{data?.shards+SHARD_AMOUNTS[amount-1]?.amount}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack orientation="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h7" color="secondary" sx={{fontSize:'12px'}}>Donation amount</Typography>
                                        <Typography variant="h7" sx={{fontSize:'16px', color:'white'}}>{SHARD_AMOUNTS[amount-1]?.price}zł</Typography>
                                    </Stack>
                                    <Stack orientation="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h7" color="secondary" sx={{fontSize:'12px'}}>Confirm details</Typography>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={confirmed} onChange={(e)=>{setConfirmed(e.target.checked)}} size="small" sx={{marginLeft:'15px'}}/>} label="Confirm" />
                                        </FormGroup>
                                    </Stack>
                                </Stack>
                                </div>
                            ) : (
                                <div>
                                <DialogContentText>
                                    Now you may send the payment with the details listed below. The shards will be added to your account within 1-3 work days after the payment is received.
                                </DialogContentText>
                                <Divider sx={{marginY: '10px'}}/>
                                <Paper elevation={12} sx={{padding:'10px', backgroundColor:'#1c1c4c'}}>
                                    <Stack orientation="column" justifyContent="center" alignItems="center" spacing={2}>
                                        <Stack orientation="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h7" sx={{fontSize:'14px', color:'lightgray'}}>Payment title:</Typography>
                                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                <Grid item xs="auto">
                                                    <Typography variant="h7" sx={{fontSize:'18px', color:'white'}}>{paymentData?.titleCode}</Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Tooltip title="Copy title">
                                                        <IconButton
                                                            size="small"
                                                            aria-label="copy"
                                                            aria-controls="copy-title"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(paymentData?.titleCode);
                                                                setInfoMessage("Copied to clipboard.");
                                                                setInfoOpen(true);
                                                            }}
                                                            sx={{color:'lightgray'}}>
                                                            <ContentCopy />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        <Stack orientation="column" justifyContent="center" alignItems="center">
                                            <Typography variant="h7" sx={{fontSize:'14px', color:'lightgray'}}>Receiver account number:</Typography>
                                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                <Grid item xs="auto">
                                                    <Typography variant="h7" sx={{fontSize:'18px', color:'white'}}>{paymentData?.receiverAccountNumber}</Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Tooltip title="Copy account number">
                                                        <IconButton
                                                            size="small"
                                                            aria-label="copy"
                                                            aria-controls="copy-number"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(paymentData?.receiverAccountNumber);
                                                                setInfoMessage("Copied to clipboard.");
                                                                setInfoOpen(true);
                                                            }}
                                                            sx={{color:'lightgray'}}>
                                                            <ContentCopy />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        <Stack orientation="column" justifyContent="center" alignItems="center">
                                            <Typography variant="h7" sx={{fontSize:'14px', color:'lightgray'}}>Receiver name:</Typography>
                                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                <Grid item xs="auto">
                                                    <Typography variant="h7" sx={{fontSize:'18px', color:'white'}}>{paymentData?.receiverName}</Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Tooltip title="Copy receiver name">
                                                        <IconButton
                                                            size="small"
                                                            aria-label="copy"
                                                            aria-controls="copy-number"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(paymentData?.receiverName);
                                                                setInfoMessage("Copied to clipboard.");
                                                                setInfoOpen(true);
                                                            }}
                                                            sx={{color:'lightgray'}}>
                                                            <ContentCopy />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        <Stack orientation="column" justifyContent="center" alignItems="center">
                                            <Typography variant="h7" sx={{fontSize:'14px', color:'lightgray'}}>Amount:</Typography>
                                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                <Grid item xs="auto">
                                                    <Typography variant="h7" sx={{fontSize:'18px', color:'white'}}>{paymentData?.amount}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </Paper>
                                <Grid container orientation="row" justifyContent="flex-start" spacing={1} sx={{marginTop:'10px'}}>
                                    <Grid item xs="auto"> 
                                        <ContactSupportIcon color="primary"/>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Link component="button" onClick={()=>{window.open('https://discord.com/invite/8t5rkpNu2a')}} color="primary" sx={{fontSize:'14px', textTransform:'none'}}>
                                        Having any problem with the payment? Contact us here
                                        </Link>
                                    </Grid>
                                </Grid>
                                </div>
                            ))
                            }
                        </DialogContent>
                        <Divider sx={{marginTop: '10px'}}/>
                        <DialogActions>
                            <Button onClick={()=>{setOpenShardDialog(false);}}>Close</Button>
                            {activeStep > 0 && activeStep !== 2 ?
                            <Button onClick={()=>{setActiveStep((prevActiveStep)=> prevActiveStep - 1)}}>Back</Button> : null
                            }
                            {activeStep === 2 ?
                                <Button onClick={()=>{
                                    setOpenShardDialog(false); 
                                    setActiveStep(0);
                                    setConfirmed(false);
                                }}>Finish</Button>
                                : (activeStep === 1 ? 
                                <Tooltip title={!confirmed ? "Confirm the details first" : ""}>
                                    <span>
                                    <Button disabled={!confirmed} onClick={()=>{handleDonationRequest();}}>Next</Button>
                                    </span>
                                </Tooltip>
                                    :
                                <Tooltip title={amount === 0 ? "Amount not selected" : ""}>
                                    <span>
                                    <Button disabled={amount === 0} onClick={()=>{setActiveStep((prevActiveStep)=> prevActiveStep + 1);}}>Next</Button>
                                    </span>
                                </Tooltip>)
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openTwoFactorEnableDialog} onClose={()=>{setOpenTwoFactorEnableDialog(false);}}>
                        <DialogTitle>Enable Two-Factor Authentication</DialogTitle>
                        <DialogContent>
                            <Stepper activeStep={activeStepTwoFactor} sx={{marginBottom:'10px'}}>
                                {twoFactorSteps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                            <Divider sx={{marginY: '10px'}}/>
                            { activeStepTwoFactor === 0 ? <Typography variant="h7" sx={{color:'white', fontSize:'15px', margin:'15px'}}>To enable two-factor authentication on your account, first you have to install a trusted authentication app on your phone. We recommend using Microsoft Authenticator, Google Authenticator or Authy. You may find those on Play Store or Apple Store. If you lose access to your phone or application, you will be able to reset the two-factor code using your email. Proceed to the next step if you've installed the application.</Typography>
                            : (activeStepTwoFactor === 1 ? 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Paper elevation={8} sx={{padding:'8px', backgroundColor:'white' }}>
                                    <QRCode value={qrKey} size={128} />
                                </Paper>
                                <Typography variant="h7" sx={{color:'white', fontSize:'15px', margin:'15px'}}>You may now scan this QR Code with your application. Search for 'Scan QR' option and point your phone camera at the code. Once you've added the 'Dragon Ball World' to your app, proceed to the next step to verify and finish two-factor authentication.</Typography>
                            </div>
                            : <Stack>
                                <Typography variant="h7" sx={{color:'white', fontSize:'15px', margin:'15px'}}>Please provide the code from your authentication app found at 'Dragon Ball World' section. It will confirm you've succeeded setting up the two-factor authentication.</Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <TextField variant="outlined"
                                            required
                                            id="twoFactorCode"
                                            label="Auth Code"
                                            autoComplete="off"
                                            color="primary"
                                            value={code}
                                            ref={codeRef}
                                            onInput={e=>setCode(e.target.value)} 
                                            sx={{
                                                input:{color:'white', textAlign:'center'}
                                            }}/>
                                </div>
                            </Stack>)
                            }
                            <Divider sx={{marginY: '10px'}}/>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs="auto">
                                    <Grid container orientation="row" justifyContent="center" spacing={1}>
                                        <Grid item xs="auto"> 
                                            <InfoIcon sx={{color:'lightgreen'}}/>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography variant="h7" sx={{color:'lightgreen', fontSize:'15px'}}>
                                            If you encounter any problems please contact us on discord or forum.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{window.open('https://dbwots.pl/');}}>Learn More</Button>
                            <Button onClick={()=>{setOpenTwoFactorEnableDialog(false);}}>Close</Button>
                            {activeStepTwoFactor > 0 ?
                            <Button onClick={()=>{setActiveStepTwoFactor((prevActiveStep)=> prevActiveStep - 1)}}>Back</Button> : null
                            }
                            {activeStepTwoFactor === 2 ?
                                <Button onClick={()=>{
                                    onTwoFactorVerify();
                                }}>Verify</Button>
                                : (activeStep === 1 ? 
                                <Button onClick={()=>{setActiveStepTwoFactor((prevActiveStep)=> prevActiveStep + 1);}}>Next</Button>
                                    :
                                <Button onClick={()=>{setActiveStepTwoFactor((prevActiveStep)=> prevActiveStep + 1);}}>Next</Button>)
                            }
                        </DialogActions>
                    </Dialog>
                    <Divider sx={{marginTop:'5px'}}/>
                    <Stack orientation="column">
                        <Button color="secondary" sx={{
                            marginBottom:'10px',
                            marginTop:'10px'
                        }} onClick={()=> {
                            navigate('/account');
                        }}>VIEW CHARACTERS</Button>
                        <Button color="secondary" sx={{
                            marginBottom:'10px',
                        }} onClick={()=> {
                            sendChangePasswordRequest(data?.email);
                        }}>CHANGE PASSWORD</Button>
                        {!data?.confirmed ?
                        <Button color="secondary" sx={{
                            marginBottom:'10px',
                        }} onClick={()=> {
                            sendEmailConfirmation(data?.email);
                        }}>RESEND CONFIRMATION EMAIL</Button> 
                        : null}
                        <Button color="secondary" sx={{
                            marginBottom:'10px',
                        }} onClick={()=> {
                            logout();
                        }}>LOGOUT</Button>
                    </Stack>
            </Paper>
        </Box>
    </Container>
  )
}