import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppWrapper } from './Components/AppWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppWrapper>
      <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App/>} />
          </Routes>
      </BrowserRouter>
  </AppWrapper>
);